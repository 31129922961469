export const settings = {
  speed: .5,
  gap: 1,
  timeInMinute: 60,
}

export function bpmToSeconds(bpm: number) {
  return (bpm / settings.timeInMinute)
}

export function timeConvert({ bpm, time }:{ bpm: number, time: number }) {
  return (((time * settings.gap) * bpm) * bpmToSeconds(bpm)) * settings.speed
}

export function rowTime({bpm, time}:{bpm: number, time: number}) {
  return (bpm * (time * settings.gap)) * settings.speed
}