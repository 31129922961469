import React, { useState, useEffect, useRef } from 'react';

import useEventListener from '../elements/useEventListener';

import type * as Schemas from '../schema/types';

interface callerProps {
    audio: {
        playing: boolean,
        toggle: () => void,
        volume: number,
        changeVol: (vol: number) => void,
        muted: boolean,
        muteVol: () => void,
        audioCurrentTime: number,
    },
    mapInfo: Schemas.MapInfo,
    mapData: Schemas.MapData
}

function useInterval(callback: Function, delay: number | null) {
    const savedCallback = useRef<any>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            tick();
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const Map = (caller: callerProps) => {
    const [speed, setSpeed] = useState<number>(8);
    const [gap, setGap] = useState<number>(8);

    function LoadNotes(row: Schemas.RowOptions) {
        const Notes:Array<any> = [];
        caller.mapData.notes.forEach((note, index) => {
            if (note.row === row) {
                if ((speed * (caller.audio.audioCurrentTime / (60 / caller.mapInfo.beatsPerMinute)) - note.time * gap) + 35 > 38) {
                    caller.mapData.notes.splice(index, 1);
                    console.log('missed');
                    return `hidden`
                }
                Notes.push(<div
                    className={`bg-black h-[36px] w-[36px] m-[2.5px] z-50 absolute rounded-lg text-center text-white ${
                        (speed * (caller.audio.audioCurrentTime / (60 / caller.mapInfo.beatsPerMinute)) - note.time * gap) + 35 > 0 
                        ? '' : 'hidden'
                    }
                    
                    ${(speed * (caller.audio.audioCurrentTime / (60 / caller.mapInfo.beatsPerMinute)) - note.time * gap) + 35 > 38 ?
                        `hidden` : null
                    }
                `}
                    style={{ top: `${(speed * (caller.audio.audioCurrentTime / (60 / caller.mapInfo.beatsPerMinute)) - note.time * gap) + 35}rem` }}
                >
                    {note.time} 
                </div>)
            } 
        });
        return Notes as Array<any>;
    }

    /*useEffect(() => {
        if ((caller.audio.audioCurrentTime / (60 / caller.mapInfo.beatsPerMinute)).toFixed(1) === '1.3') { 
            return caller.audio.toggle();
        }
    }, [caller.audio.audioCurrentTime])*/

    const pressRow = (number: number) => {
        if (!caller.audio.playing) return;
        caller.mapData.notes.forEach((note, index) => {
            if (note.row === number) {
                console.log((Math.abs(caller.audio.audioCurrentTime / (60 / caller.mapInfo.beatsPerMinute) - note.time).toFixed(1)))
                switch (Math.abs(caller.audio.audioCurrentTime / (60 / caller.mapInfo.beatsPerMinute) - note.time).toFixed(1))  {
                    case "0.0":
                        console.log('Perfect');
                        caller.mapData.notes.splice(index, 1)
                        return;
                
                    case "0.1":
                        console.log('Great');
                        caller.mapData.notes.splice(index, 1)
                        return;
                    
                    case "0.2":
                        console.log('Good');
                        caller.mapData.notes.splice(index, 1)
                        return;
                }
            }
        });
    }

    const onKeyDown = (event: any) => {
        if (!event.key) return;
        switch (event.key) {
            case ' ':
                caller.audio.toggle(); event.preventDefault();
                break;

            case 'ArrowUp':
                caller.audio.changeVol(caller.audio.volume + .05); event.preventDefault();
                break;

            case 'ArrowDown':
                caller.audio.changeVol(caller.audio.volume - .05); event.preventDefault();
                break;

            case 'Escape':
                caller.audio.muteVol(); event.preventDefault();
                break;

            case `a`:
                pressRow(1);
                break;

            case `s`:
                pressRow(2);
                break;

            case `l`:
                pressRow(3);
                break;

            case `;`:
                pressRow(4);
                break;
        }
    }

    function Hitter(params: any) {
        return <div className='w-8 h-8 m-1 bg-zinc-800'></div>
    }

    useEventListener('keydown', onKeyDown)

    return (
        <div className='bg-slate-900 align-middle h-fit'>
            <div className='relative flex flex-row'>
                <div className='bg-base-dark w-40 h-10 absolute z-20 top-[562px] flex flex-row'>
                    <Hitter />
                    <Hitter />
                    <Hitter />
                    <Hitter />
                </div>

                <div className='bg-white h-[40rem] w-10 relative' id='row-1'>
                    {LoadNotes(1)}
                </div>

                <div className='bg-accent-1 h-[40rem] w-10 relative' id='row-2'>
                    {LoadNotes(2)}
                </div>

                <div className='bg-white h-[40rem] w-10 relative' id='row-3'>
                    {LoadNotes(3)}
                </div>

                <div className='bg-accent-1 h-[40rem] w-10 relative' id='row-4'>
                    {LoadNotes(4)}
                </div>
            </div>
        </div>

    )
}

export default Map;