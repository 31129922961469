import { Settings } from "../../schema/types";

export const defaultSettings: Settings = {
  audio: {
    masterVolume: 1,
    music: 0.5,
    bgMusic: 0.5,
    sfx: 0.5,
  },
  keybinds: {
    restart: 'r',
    musicUp: 'ArrowUp',
    musicDown: 'ArrowDown',
    pause: 'Space',
    track1: 'a',
    track2: 's',
    track3: 'l',
    track4: ';',
  },
  visuals: {
    notes: {
      colors: {
        track1: {
          miss: '#ff0000',
          hit: '#00ff00',
          base: '#e06c00',
        },
        track2: {
          miss: '#ff0000',
          hit: '#00ff00',
          base: '#e06c00',
        },
        track3: {
          miss: '#ff0000',
          hit: '#00ff00',
          base: '#e06c00',
        },
        track4: {
          miss: '#ff0000',
          hit: '#00ff00',
          base: '#e06c00',
        },
      },
    },
    tracks: {
      colors: {
        track4: '#fff',
        track3: '#cb71ff',
        track2: '#fff',
        track1: '#cb71ff',
      },
      glow: true,
    },
    text: {
      font: 'Arial',
      color: '#cb71ff',
      size: 12,
      glow: false,
    },
  },
  camera: {
    x: 30,
    y: 15,
    z: 0,
    lobby: {
      x: 30,
      y: 30,
      z: 30,
    },
    fov: 90,
    zoom: 1,
  },
  _id: '',
}

export default defaultSettings;