import { useEffect, useState } from 'react'
import Stage from './map/stage'
import type { SongPage, MapInfo } from '../schema/types'

import axios from 'axios'
import ThreeCircle from '../componets/loading';
import { motion } from 'framer-motion';

function Map3d() {
  const [mapID, setMapID] = useState<string>()
  const [page, setPage] = useState<number>(0)
  const [pageData, setPageData] = useState<SongPage>()
  const [playing, setplaying] = useState<boolean>(false)

  useEffect(() => {
    new Promise<SongPage>(async (resolve) => {
      const getMap = await axios(`https://api.wayofthat.com/api/v1/page/${page}`)
      console.log(getMap)
      setPageData(getMap.data as SongPage)
    })
  }, [page])

  const [hover, setHover] = useState<string>('')
  const onHover = (id: string) => {
    setHover(id)
  }

  const onLeave = () => {
    setHover('')
  }

  const slecetMap = (id: string) => {
    console.log(id)
    setMapID(id)
  }

  function SelectSong(): any {
    if (!pageData) return  <div className='overflow-hidden text-white'><motion.div
        animate={{
          rotate: 360,
          repeatCount: Infinity,
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatDelay: 1,
          delay: 1.2,
        }}
        className="flex flex-row gap-4 items-center justify-center h-20"
      >
        <ThreeCircle />
      </motion.div>
      </div> 
    const array: JSX.Element[] = []
    pageData.songs.forEach((song, index) => {
      array.push(
        <div className="relative m-4">
          {index >= 1 ? (
            <div className="h-[2px] bg-accent-1 w-full mb-8"></div>
          ) : null}
          <div
            onClick={() => {
              slecetMap(song._id)
              setplaying(true)
            }}
            className="flex flex-row h-32 items-center content-start text-white cursor-pointer select-none"
          >
            <img
              src={`https://api.wayofthat.com/api/v1/${song._id}/info/picture.png`}
              alt="cover"
              className="w-32 h-32 rounded-lg"
            />

            <div className="flex flex-col content-start items-center w-full ml-2 relative">
              {hover === song._id ? (
                <div
                  className={`absolute ${
                    index == 0 ? `-bottom-10` : `-top-28`
                  } pointer-events-none z-20 p-2 max-h-32 w-full rounded-lg bg-base-dark bg-opacity-90 border-accent-1 border-2`}
                >
                  <div className="flex flex-col content-start items-center m-2">
                    <p>Difficulty: {song.difficulty} / 5</p>
                    <p>BPM {song.beatsPerMinute}</p>
                    <p>Version: {song.version}</p>
                  </div>
                </div>
              ) : null}
              <p
                onMouseEnter={() => onHover(song._id)}
                onMouseLeave={() => onLeave()}
                className="max-h-32"
              >
                {song.title}
              </p>
              <p className="text-gray-300">{song.author}</p>
              <div className="bg-accent-1 h-[2px] my-2 w-full"></div>
              <p className="text-md">Maker: {song.levelAuthorName}</p>
            </div>
          </div>
        </div>,
      )
    })
    return array
  }

  return (
    <div className="w-full h-screen ">
      <div className="flex flex-col h-full z-0  items-center">
        <Stage playing={playing} mapID={mapID} setMapID={setMapID} />
        { mapID ? null : 
          <div className="flex felx-row h-full items-center">
            <div className="w-96 border-2 border-accent-1 rounded-md shadow-md bg-base-dark/90 shadow-accent-1/20">
              <div className="flex flex-col justify-center items-center h-full">
                <div className="text-center text-xl text-white">
                  <h1>Welcome!</h1>
                  <p>Select a song to start playing!</p>
                </div>
                <div className="mt-2 max-h-96 flex flex-col items-stretch scroll-smooth overflow-y-auto overscroll-y-contain">
                  {SelectSong() || null}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Map3d
