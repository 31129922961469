import { useThree, useFrame, extend } from "@react-three/fiber";
import { useState, useRef, useEffect } from "react";

import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'

import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'

import { EffectComposer, DepthOfField, Bloom as Bloomer, Noise, Selection } from '@react-three/postprocessing'

extend({ UnrealBloomPass, EffectComposer, RenderPass })

/*
export function Bloom({ children }: any) {
  const { gl, camera, size } = useThree()
  const [scene, setScene] = useState<any>()
  const composer = useRef<any>()
  useEffect(
    () => void scene && composer.current.setSize(size.width, size.height),
    [size],
  )
  useFrame(() => {
    composer.current.setSize(size.width, size.height)
    return scene && composer.current.render()
  }, 1)

  return (
    <>
      <scene ref={setScene}>{children}</scene>
      <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        { /** 
         * I Have no idea why this is erroring out.
         * Its a bug in the extend code and i dont want to upgrade to the new version because it will all break.
         * 
         * Note - Chance (the only dev) has been notified of this issue.
         * */ 

        /* @ts-ignore }
        <unrealBloomPass
          attachArray="passes"
          args={[undefined, 0.3, 0.1, 0]}
        />
      </effectComposer>
    </>
  )
}
*/

export function Bloom({ children }: any) {
  return <Selection>
      {children}
      <EffectComposer>
        <Bloomer luminanceThreshold={0.7} luminanceSmoothing={0.1} height={1000} />
        <Noise opacity={0.025} />
      </EffectComposer>
    </Selection>
}

export default {Bloom}