import { Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useState } from "react";
import { Settings } from "../../../schema/types";

import globalVariables from "../globalVariables";

function GetLastHit({ settings }: { settings: Settings }) {
  const [text, setText] = useState<string>(globalVariables.noteText)
  useFrame((state) => {
    if (globalVariables.noteHit.length > 2) globalVariables.noteHit.shift()
    globalVariables.noteText = globalVariables.noteHit.join('\n')
    setText(globalVariables.noteText)
  })

  return (
    <Text
      color={settings.visuals.text.color}
      fontSize={settings.visuals.text.size}
      maxWidth={200}
      font={settings.visuals.text.font}
      lineHeight={1}
      letterSpacing={0.02}
      textAlign={'center'}
    >
      {globalVariables.noteText === '' ? '' : globalVariables.noteText}
    </Text>
  )
}

export default GetLastHit;