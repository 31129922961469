import React, { useState, useEffect, useMemo, memo } from 'react'
import axios from 'axios'
import { motion, AnimatePresence } from 'framer-motion'
import { HexColorPicker, HexColorInput } from 'react-colorful'

import { ReactComponent as Gear } from '../assets/settings.svg'
import { ReactComponent as Close } from '../assets/close-circle.svg'
import { ReactComponent as ArrowDown } from '../assets/arrowDown.svg'

import _ from 'lodash'

import type { Settings as TypeSettings, MapInfo } from '../schema/types'
import useEventListener from '../elements/useEventListener'

const lens = (obj: any, path: string) =>
  path.split('.').reduce((o, key) => (o && o[key] ? o[key] : null), obj)

function Settings({
  audio,
  mapID,
  setMapID,
  settings,
  mapInfo,
  setSettings,
  newError,
}: {
  audio: any
  setMapID: React.Dispatch<React.SetStateAction<string | undefined>>
  mapID: string | undefined
  settings: TypeSettings
  setSettings: (settings: TypeSettings) => void
  mapInfo: MapInfo
  newError: (err: string) => void
}): JSX.Element {
  const [CurrentMapId] = useState(mapID)
  const toggleMapSelection = () => {
    !mapID ? setMapID(CurrentMapId) : setMapID(undefined)
  }

  const [stateSettings, setStateSettings] = useState<TypeSettings>(settings)
  const [music, setMusic] = useState<number>(settings.audio.music)
  const [master, setMaster] = useState<number>(settings.audio.masterVolume)
  const [sfx, setSfx] = useState<number>(settings.audio.sfx)
  const [bgMusic, setBgMusic] = useState<number>(settings.audio.bgMusic)

  const [page, setPage] = useState<
    'Video' | 'Audio' | 'Controls' | 'Appearance'
  >('Appearance')
  const [bigMenu, toggleBigMenu] = useState(false)

  const [controls, setControls] = useState<any>({
    row1: settings.keybinds.track1,
    row2: settings.keybinds.track2,
    row3: settings.keybinds.track3,
    row4: settings.keybinds.track4,
  })

  useEffect(() => {
    settings.keybinds.track1 = controls.row1
    settings.keybinds.track2 = controls.row2
    settings.keybinds.track3 = controls.row3
    settings.keybinds.track4 = controls.row4
  }, [controls])

  useEffect(() => {
    setControls({
      row1: settings.keybinds.track1,
      row2: settings.keybinds.track2,
      row3: settings.keybinds.track3,
      row4: settings.keybinds.track4,
    })

    setMusic(settings.audio.music)
    setMaster(settings.audio.masterVolume)
    setSfx(settings.audio.sfx)
    setBgMusic(settings.audio.bgMusic)

    setStateSettings(settings)
  }, [settings._id])

  useEffect(() => {
    audio.volume = music
    settings.audio.music = music
  }, [music])

  useEffect(() => {
    settings.audio.sfx = sfx
  }, [sfx])
  useEffect(() => {
    settings.audio.bgMusic = bgMusic
  }, [bgMusic])
  useEffect(() => {
    settings.audio.masterVolume = master
    audio.volume = settings.audio.music * master
  }, [master])

  const SaveSettings = async () => {
    console.log('WOT: saving settings')
    const configPatch = await axios.patch(
      `https://api.wayofthat.com/api/v1/user/${window.sessionStorage.getItem(
        'id',
      )}`,
      settings,
      {
        headers: {
          Authorization: `Bearer ${window.sessionStorage.getItem('token')}`,
        },
        validateStatus: () => true,
      },
    )
    console.log('WOT: settings req sent')
    if (configPatch.status !== 200) {
      setSettings(settings)
      return newError('Error saving settings to cloud (Are you logged in?)')
    }
    setSettings(settings)
  }

  const [colorMenu, setColorMenu] = useState({
    note1: {
      miss: false,
      hit: false,
      base: false,
    },
    note2: {
      miss: false,
      hit: false,
      base: false,
    },
    note3: {
      miss: false,
      hit: false,
      base: false,
    },
    note4: {
      miss: false,
      hit: false,
      base: false,
    },
  })

  const [trackMenu, setTrackMenu] = useState({
    track1: false,
    track2: false,
    track3: false,
    track4: false,
  })

  const [text, setText] = useState({
    textColor: false,
  })

  const ColorMenu = ({
    setting,
    path,
  }: {
    setting: TypeSettings
    path: string
  }) => {
    console.log(_.get(setting, path))
    let difsetting = setting
    const [b, setB] = useState(_.get(difsetting, path))
    return (
      <motion.div
        key={path}
        className="z-50"
        style={{
          top: '32px',
          right: 'px',
          position: 'absolute',
        }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.2 }}
      >
        <HexColorPicker
          key={path + 'picker'}
          color={b}
          onChange={(color: string) => {
            setB(color)
            _.set(difsetting, path, color)
            setStateSettings(difsetting)
          }}
        />
      </motion.div>
    )
  }

  const ColorInput = ({
    setting,
    path,
    color,
  }: {
    setting: TypeSettings
    path: string
    color: string
  }) => {
    let difsetting = setting
    const [b, setB] = useState(_.get(difsetting, path))
    return (
      <HexColorInput
        className="bg-transparent w-16 focus:outline-none focus:ring-0"
        key={path + 'input'}
        prefixed
        style={{
          color: b,
        }}
        color={b}
        onChange={(e: string) => {
          setB(e)
          _.set(difsetting, path, e)
          setStateSettings(difsetting)
        }}
      />
    )
  }

  const Input = ({
    setting,
    path,
    type,
    state,
  }: {
    setting: TypeSettings
    path: string
    type: string
    state: string | number
  }) => {
    const [v, setV] = useState(state)
    let difsetting = setting

    return (
      <input
        type={type}
        value={v}
        className="bg-transparent w-8 rounded-md focus:outline-none focus:ring-0 pl-1"
        onChange={(e) => {
          console.log(e.target.value)
          if (type === 'number' && e.target.value === '') setV(0)
          else {
            setV(e.target.value)
            _.set(difsetting, path, e.target.value)
            setStateSettings(difsetting)
          }
        }}
      />
    )
  }

  const KeyInput = ({
    setting,
    path,
    type,
    state,
  }: {
    setting: TypeSettings
    path: string
    type: string
    state: string
  }) => {
    const [v, setV] = useState(state)
    let difsetting = setting

    return (
      <input
        type={type}
        value={v}
        className="bg-accent-1 w-8 text-center min-w-fit rounded-md focus:outline-none focus:ring-0 pl-1"
        onKeyDown={(e) => {
          e.preventDefault()
          console.log(e.key)
          _.set(difsetting, path, e.key)
          setStateSettings(difsetting)
          setV(e.key)
        }}
        onClick={() => {
          setV('')
        }}
        onBlur={() => {
          setV(_.get(difsetting, path))
        }}
      />
    )
  }

  const Range = ({
    setting,
    path,
    state,
    sliderInfo,
  }: {
    setting: TypeSettings
    path: string
    state: number
    sliderInfo: { min: number; max: number; step: number }
  }) => {
    const [v, setV] = useState(state)
    let difsetting = setting

    return (
      <>
        <input
          type="range"
          value={v}
          className="appearance-none slider w-[140px] h-2 ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
          min={sliderInfo.min}
          max={sliderInfo.max}
          step={sliderInfo.step}
          onChange={(e) => {
            setV(e.target.valueAsNumber)
            _.set(difsetting, path, e.target.valueAsNumber)
            setStateSettings(difsetting)
          }}
        />
        <input
          type="number"
          style={{ appearance: 'none' }}
          className="appearance-none m-[4px] h-4 w-8 bg-transparent text-white text-center focus:outline-none focus:ring-0"
          value={Math.round(v * 100).toString()}
          maxLength={3}
          onChange={(e: any) => {
            if (e.target.value * 1 <= 100) {
              setV((e.target.value * 1) / 100)
              _.set(difsetting, path, e.target.value * 1)
              setStateSettings(difsetting)
            }
          }}
        />
      </>
    )
  }

  const SelectedPage = ({
    page,
  }: {
    page: 'Video' | 'Audio' | 'Controls' | 'Appearance'
  }) => {
    switch (page) {
      case 'Video':
        return (
          <div className="h-full w-full p-2">
            <div className="flex flex-col h-full justify-center space-y-12 overflow-y-scroll">
              <div className="flex flex-row w-full min-h-[4rem] justify-start items-center text-white">
                <p className="w-[15rem] text-center text-2xl">Camera</p>
                <div className="border border-accent-1 h-full"></div>

                <div className="flex flex-col space-y-8 grow items-center justify-center">
                  <div>
                    <p className="text-center text-lg">Camera Settings</p>

                    <div className="flex flex-row flex-wrap justify-center items-center">
                      <>
                        <p className="select-none text-center p-2">
                          Camera X:&nbsp;
                          <Input
                            type="number"
                            setting={stateSettings}
                            path="camera.x"
                            state={stateSettings.camera.x}
                          />
                        </p>
                      </>
                      <>
                        <p className="select-none text-center p-2">
                          Camera Y:&nbsp;
                          <Input
                            type="number"
                            setting={stateSettings}
                            path="camera.y"
                            state={stateSettings.camera.y}
                          />
                        </p>
                      </>
                      <>
                        <p className="select-none text-center p-2">
                          Camera F.O.V:&nbsp;
                          <Input
                            type="number"
                            setting={stateSettings}
                            path="camera.fov"
                            state={stateSettings.camera.fov}
                          />
                        </p>
                      </>
                      <>
                        <p className="select-none text-center p-2">
                          Camera Zoom:&nbsp;
                          <Input
                            type="number"
                            setting={stateSettings}
                            path="camera.zoom"
                            state={stateSettings.camera.zoom}
                          />
                        </p>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      case 'Audio':
        return (
          <div className="h-full w-full p-2">
            <div className="flex flex-col h-full justify-center space-y-12 overflow-y-scroll">
              <div className="flex flex-row w-full min-h-[4rem] justify-start items-center text-white">
                <p className="w-[15rem] text-center text-2xl">Audio</p>
                <div className="border border-accent-1 h-full"></div>
                <div className="flex flex-col space-y-8 grow items-center justify-center">
                  <div>
                    <p className="text-center text-lg">Audio Sliders</p>

                    <div className="flex flex-row items-center justify-items-center">
                      <p className="text-base text-white grow">Master</p>
                      <Range
                        setting={stateSettings}
                        path="audio.masterVolume"
                        state={stateSettings.audio.masterVolume}
                        sliderInfo={{
                          min: 0,
                          max: 1,
                          step: 0.01,
                        }}
                      />
                    </div>
                    <div className="flex flex-row items-center justify-items-center">
                      <p className="text-base text-white grow">Music</p>
                      <Range
                        setting={stateSettings}
                        path="audio.music"
                        state={stateSettings.audio.music}
                        sliderInfo={{
                          min: 0,
                          max: 1,
                          step: 0.01,
                        }}
                      />
                    </div>
                    <div className="flex flex-row items-center justify-items-center">
                      <p className="text-base text-white grow">Lobby</p>
                      <Range
                        setting={stateSettings}
                        path="audio.music"
                        state={stateSettings.audio.bgMusic}
                        sliderInfo={{
                          min: 0,
                          max: 1,
                          step: 0.01,
                        }}
                      />
                    </div>
                    <div className="flex flex-row items-center justify-items-center">
                      <p className="text-base text-white grow">SFX</p>
                      <Range
                        setting={stateSettings}
                        path="audio.sfx"
                        state={stateSettings.audio.sfx}
                        sliderInfo={{
                          min: 0,
                          max: 1,
                          step: 0.01,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      case 'Controls':
        return (
          <div className="h-full w-full p-2">
            <div className="flex flex-col h-full justify-center space-y-12 overflow-y-scroll">
              <div className="flex flex-row w-full min-h-[4rem] justify-start items-center text-white">
                <p className="w-[15rem] text-center text-2xl">Keys</p>
                <div className="border border-accent-1 h-full"></div>
                <div className="flex flex-col space-y-8 grow items-center justify-center">
                  <div>
                    <p className="text-center text-lg">Random Controls</p>

                    <div className="flex flex-row flex-wrap justify-center items-center">
                      <>
                        <p className="select-none text-center p-2">
                          Restart Notes:&nbsp;
                          <KeyInput
                            type="text"
                            setting={stateSettings}
                            path="keybinds.restart"
                            state={stateSettings.keybinds.restart}
                          />
                        </p>
                      </>
                      <>
                        <p className="select-none text-center p-2">
                          Music Up:&nbsp;
                          <KeyInput
                            type="text"
                            setting={stateSettings}
                            path="keybinds.musicUp"
                            state={stateSettings.keybinds.musicUp}
                          />
                        </p>
                      </>
                      <>
                        <p className="select-none text-center p-2">
                          Music Down:&nbsp;
                          <KeyInput
                            type="text"
                            setting={stateSettings}
                            path="keybinds.musicDown"
                            state={stateSettings.keybinds.musicDown}
                          />
                        </p>
                      </>
                    </div>
                  </div>
                  <div>
                    <p className="text-center text-lg">GamePlay Controls</p>
                    <div className="flex flex-row flex-wrap justify-center items-center">
                      <>
                        <p className="select-none text-center p-2">
                          Track 1:&nbsp;
                          <KeyInput
                            type="text"
                            setting={stateSettings}
                            path="keybinds.track1"
                            state={stateSettings.keybinds.track1}
                          />
                        </p>
                      </>
                      <>
                        <p className="select-none text-center p-2">
                          Track 2:&nbsp;
                          <KeyInput
                            type="text"
                            setting={stateSettings}
                            path="keybinds.track2"
                            state={stateSettings.keybinds.track2}
                          />
                        </p>
                      </>
                      <>
                        <p className="select-none text-center p-2">
                          Track 3:&nbsp;
                          <KeyInput
                            type="text"
                            setting={stateSettings}
                            path="keybinds.track3"
                            state={stateSettings.keybinds.track3}
                          />
                        </p>
                      </>
                      <>
                        <p className="select-none text-center p-2">
                          Track 4:&nbsp;
                          <KeyInput
                            type="text"
                            setting={stateSettings}
                            path="keybinds.track4"
                            state={stateSettings.keybinds.track4}
                          />
                        </p>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      case 'Appearance':
        return (
          <div className="h-full w-full p-2">
            <div className="flex flex-col h-full justify-center space-y-12 overflow-y-scroll">
              <div className="flex flex-row w-full min-h-fit justify-start items-center text-white">
                <p className="w-[15rem] text-center text-2xl">Notes</p>

                <div className="border border-accent-1 h-full"></div>

                <div className="flex flex-col space-y-4 grow">
                  <div className="min-h-fit h-fit">
                    <p className="text-center text-lg">Track 1 Notes</p>
                    <div className="flex flex-row justify-center items-center space-x-3">
                      <p className="select-none">Base:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note1.base && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track1.base"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track1.base"
                          color={stateSettings.visuals.notes.colors.track1.base}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note1: {
                                miss: false,
                                hit: false,
                                base: !colorMenu.note1.base,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note3: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>

                      <p className="select-none">Hit:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note1.hit && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track1.hit"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track1.hit"
                          color={stateSettings.visuals.notes.colors.track1.hit}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note1: {
                                miss: false,
                                hit: !colorMenu.note1.hit,
                                base: false,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note3: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>

                      <p className="select-none">Miss:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note1.miss && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track1.miss"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track1.miss"
                          color={stateSettings.visuals.notes.colors.track1.miss}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note1: {
                                miss: !colorMenu.note1.miss,
                                hit: false,
                                base: false,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note3: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="h-fit">
                    <p className="text-center text-lg">Track 2 Notes</p>
                    <div className="flex flex-row justify-center items-center space-x-3">
                      <p className="select-none">Base:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note2.base && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track2.base"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track2.base"
                          color={stateSettings.visuals.notes.colors.track2.base}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note2: {
                                miss: false,
                                hit: false,
                                base: !colorMenu.note2.base,
                              },
                              note1: { miss: false, hit: false, base: false },
                              note3: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>

                      <p className="select-none">Hit:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note2.hit && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track2.hit"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track2.hit"
                          color={stateSettings.visuals.notes.colors.track2.hit}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note2: {
                                miss: false,
                                hit: !colorMenu.note2.hit,
                                base: false,
                              },
                              note1: { miss: false, hit: false, base: false },
                              note3: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>

                      <p className="select-none">Miss:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note2.miss && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track2.miss"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track2.miss"
                          color={stateSettings.visuals.notes.colors.track2.miss}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note2: {
                                miss: !colorMenu.note2.miss,
                                hit: false,
                                base: false,
                              },
                              note3: { miss: false, hit: false, base: false },
                              note1: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="h-fit">
                    <p className="text-center text-lg">Track 3 Notes</p>
                    <div className="flex flex-row justify-center items-center space-x-3">
                      <p className="select-none">Base:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note3.base && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track3.base"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track3.base"
                          color={stateSettings.visuals.notes.colors.track3.base}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note3: {
                                miss: false,
                                hit: false,
                                base: !colorMenu.note3.base,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note1: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>

                      <p className="select-none">Hit:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note3.hit && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track3.hit"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track3.hit"
                          color={stateSettings.visuals.notes.colors.track3.hit}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note3: {
                                miss: false,
                                hit: !colorMenu.note3.hit,
                                base: false,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note1: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>

                      <p className="select-none">Miss:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note3.miss && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track3.miss"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track3.miss"
                          color={stateSettings.visuals.notes.colors.track3.miss}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note3: {
                                miss: !colorMenu.note3.miss,
                                hit: false,
                                base: false,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note1: { miss: false, hit: false, base: false },
                              note4: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="h-fit">
                    <p className="text-center text-lg">Track 4 Notes</p>
                    <div className="flex flex-row justify-center items-center space-x-3">
                      <p className="select-none">Base:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note4.base && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track4.base"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track4.base"
                          color={stateSettings.visuals.notes.colors.track4.base}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note4: {
                                miss: false,
                                hit: false,
                                base: !colorMenu.note4.base,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note3: { miss: false, hit: false, base: false },
                              note1: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>

                      <p className="select-none">Hit:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note4.hit && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track4.hit"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track4.hit"
                          color={stateSettings.visuals.notes.colors.track4.hit}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note4: {
                                miss: false,
                                hit: !colorMenu.note4.hit,
                                base: false,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note3: { miss: false, hit: false, base: false },
                              note1: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>

                      <p className="select-none">Miss:&nbsp;</p>
                      <span className="cursor-pointer relative flex flex-row">
                        {colorMenu.note4.miss && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.notes.colors.track4.miss"
                          />
                        )}
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.notes.colors.track4.miss"
                          color={stateSettings.visuals.notes.colors.track4.miss}
                        />
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setColorMenu({
                              note4: {
                                miss: !colorMenu.note4.miss,
                                hit: false,
                                base: false,
                              },
                              note2: { miss: false, hit: false, base: false },
                              note3: { miss: false, hit: false, base: false },
                              note1: { miss: false, hit: false, base: false },
                            })
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row w-full min-h-[4rem] justify-start items-center text-white">
                <p className="w-[15rem] text-center text-2xl">Tracks</p>

                <div className="border border-accent-1 h-full"></div>

                <div className="flex flex-row space-x-4 grow items-center justify-center">
                  <div className="flex flex-col">
                    <p className="text-center text-lg">Track 1 Color</p>
                    <div className="flex flex-row justify-center items-center">
                      <span className="relative">
                        {trackMenu.track1 && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.tracks.colors.track1"
                          />
                        )}
                      </span>
                      <p className="select-none text-center">
                        Primary:&nbsp;
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.tracks.colors.track1"
                          color={stateSettings.visuals.tracks.colors.track1}
                        />
                      </p>
                      <ArrowDown
                        fill="#fff"
                        onClick={() =>
                          setTrackMenu({
                            track1: !trackMenu.track1,
                            track2: false,
                            track3: false,
                            track4: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-center text-lg">Track 2 Color</p>
                    <div className="flex flex-row justify-center items-center">
                      <span className="relative">
                        {trackMenu.track2 && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.tracks.colors.track2"
                          />
                        )}
                      </span>
                      <p className="select-none text-center">
                        Primary:&nbsp;
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.tracks.colors.track2"
                          color={stateSettings.visuals.tracks.colors.track2}
                        />
                      </p>
                      <ArrowDown
                        fill="#fff"
                        onClick={() =>
                          setTrackMenu({
                            track2: !trackMenu.track2,
                            track1: false,
                            track3: false,
                            track4: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-center text-lg">Track 3 Color</p>
                    <div className="flex flex-row justify-center items-center">
                      <span className="relative">
                        {trackMenu.track3 && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.tracks.colors.track3"
                          />
                        )}
                      </span>
                      <p className="select-none text-center">
                        Primary:&nbsp;
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.tracks.colors.track3"
                          color={stateSettings.visuals.tracks.colors.track3}
                        />
                      </p>
                      <ArrowDown
                        fill="#fff"
                        onClick={() =>
                          setTrackMenu({
                            track3: !trackMenu.track3,
                            track2: false,
                            track1: false,
                            track4: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-center text-lg">Track 4 Color</p>
                    <div className="flex flex-row justify-center items-center">
                      <span className="relative">
                        {trackMenu.track4 && (
                          <ColorMenu
                            setting={stateSettings}
                            path="visuals.tracks.colors.track4"
                          />
                        )}
                      </span>
                      <p className="select-none text-center">
                        Primary:&nbsp;
                        <ColorInput
                          setting={stateSettings}
                          path="visuals.tracks.colors.track4"
                          color={stateSettings.visuals.tracks.colors.track4}
                        />
                      </p>
                      <ArrowDown
                        fill="#fff"
                        onClick={() =>
                          setTrackMenu({
                            track4: !trackMenu.track4,
                            track1: false,
                            track2: false,
                            track3: false,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row w-full min-h-[4rem] justify-start items-center text-white">
                <p className="w-[15rem] text-center text-2xl">Text</p>
                <div className="border border-accent-1 h-full"></div>
                <div className="flex flex-row space-x-4 grow items-center justify-center">
                  <div className="flex flex-col">
                    <p className="text-center text-lg">Text</p>
                    <div className="flex flex-row justify-center items-center space-x-2">
                      <>
                        <span className="relative">
                          {text.textColor && (
                            <ColorMenu
                              setting={stateSettings}
                              path="visuals.text.color"
                            />
                          )}
                        </span>
                        <p className="select-none text-center">
                          Color:&nbsp;
                          <ColorInput
                            setting={stateSettings}
                            path="visuals.text.color"
                            color={stateSettings.visuals.text.color}
                          />
                        </p>
                        <ArrowDown
                          fill="#fff"
                          onClick={() =>
                            setText({
                              textColor: !text.textColor,
                            })
                          }
                        />
                      </>

                      <>
                        <p className="select-none text-center">
                          Size:&nbsp;
                          <Input
                            type="number"
                            setting={stateSettings}
                            path="visuals.text.size"
                            state={stateSettings.visuals.text.size}
                          />
                        </p>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      default:
        return <div>{page}</div>
    }
  }

  let out = { out: false, click: false }
  const toggleOut = () => (out = { out: true, click: false })
  const reset = () => (out = { out: false, click: false })

  return (
    <>
      <div className="absolute z-10 bottom-0 left-0 w-96 h-full bg-accent-1/50 animate-fade-in-right">
        <div className="h-screen flex flex-col relative items-center justify-center ">
          <motion.button
            className="
        absolute top-2 right-2 p-2 bg-base-dark/90 rounded-md text-white duration-300 text-base
        hover:shadow-lg hover:text-accent-1 px-6"
            onClick={SaveSettings}
            whileHover={{ scale: [1.1, 1.1] }}
            animate={{ scale: [1, 1] }}
            whileTap={{ scale: [0.9, 0.9] }}
            transition={{
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'loop',
            }}
          >
            Save
          </motion.button>
          <motion.button
            className="absolute left-2 top-2 text-white text-base text-center p-2 px-6 rounded-md hover:shadow-lg border-white border-2"
            onClick={toggleMapSelection}
            whileHover={{ scale: 1 }}
            animate={{ scale: 0.95 }}
            whileTap={{ scale: 0.9 }}
            transition={{ ease: 'easeInOut' }}
          >
            Select New Song
          </motion.button>
          <div className="shadow-md bg-gradient-to-tr from-base-dark/70 to-base-dark/40 flex flex-col items-center w-2/3 mt-10 rounded-lg h-fit">
            <div className="flex flex-col items-stretch shrink">
              {mapInfo._id ? (
                <img
                  src={`https://api.wayofthat.com/api/v1/${mapInfo._id}/info/picture.png`}
                  alt="cover"
                  className="rounded-t-lg shrink border-t-2 border-l-2 border-r-2 border-accent-1"
                />
              ) : null}
              <div
                className={`bg-base-dark ${
                  mapInfo._id
                    ? 'border-b-2 border-l-2 border-r-2 rounded-b-lg'
                    : 'border-2 rounded-lg'
                } border-accent-1 items-center justify-items-center shadow-lg`}
              >
                <p className="text-white text-center text-xl max-w-[252px]">
                  {mapInfo.title}
                </p>
                <p className="text-gray-300 text-center text-md max-w-[252px]">
                  {mapInfo.author}
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full items-stretch justify-items-stretch bottom-4 p-2 grow-0 h-auto">
              <p className="text-xl text-white text-center">Audio</p>
              <div className="flex flex-row items-center justify-items-center">
                <p className="text-base text-white grow">Master</p>
                <input
                  className="appearance-none slider w-[140px] h-2 ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
                  type="range"
                  value={master}
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={(e: any) => setMaster(e.target.value * 1)}
                />
                <input
                  type="number"
                  style={{ appearance: 'none' }}
                  className="appearance-none m-[4px] h-4 w-8 bg-transparent text-white text-center focus:outline-none focus:ring-0"
                  value={Math.round(master * 100).toString()}
                  maxLength={3}
                  onChange={(e: any) => {
                    if (e.target.value * 1 <= 100) {
                      setMaster((e.target.value * 1) / 100)
                    }
                  }}
                />
              </div>
              <div className="flex flex-row items-center justify-items-center">
                <p className="text-base text-white grow">Music</p>
                <input
                  className="appearance-none slider w-[140px] h-2 ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
                  type="range"
                  value={music}
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={(e: any) => setMusic(e.target.value * 1)}
                />
                <input
                  type="number"
                  style={{ appearance: 'none' }}
                  className="appearance-none m-[4px] h-4 w-8 bg-transparent text-white text-center focus:outline-none focus:ring-0"
                  value={Math.round(music * 100).toString()}
                  maxLength={3}
                  onChange={(e: any) => {
                    if (e.target.value * 1 <= 100) {
                      setMusic((e.target.value * 1) / 100)
                    }
                  }}
                />
              </div>
              <div className="flex flex-row items-center justify-items-center">
                <p className="text-base text-white grow">Lobby</p>
                <input
                  className="appearance-none slider w-[140px] h-2 ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
                  type="range"
                  value={bgMusic}
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={(e: any) => setBgMusic(e.target.value * 1)}
                />
                <input
                  type="number"
                  style={{ appearance: 'none' }}
                  className="appearance-none m-[4px] h-4 w-8 bg-transparent text-white text-center focus:outline-none focus:ring-0"
                  value={Math.round(bgMusic * 100).toString()}
                  maxLength={3}
                  onChange={(e: any) => {
                    if (e.target.value * 1 <= 100) {
                      setBgMusic((e.target.value * 1) / 100)
                    }
                  }}
                />
              </div>
              <div className="flex flex-row items-center justify-items-center">
                <p className="text-base text-white grow">SFX</p>
                <input
                  className="appearance-none slider w-[140px] h-2 ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
                  type="range"
                  value={sfx}
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={(e: any) => setSfx(e.target.value * 1)}
                />
                <input
                  type="number"
                  style={{ appearance: 'none' }}
                  className="appearance-none m-[4px] h-4 w-8 bg-transparent text-white text-center focus:outline-none focus:ring-0"
                  value={Math.round(sfx * 100).toString()}
                  maxLength={3}
                  onChange={(e: any) => {
                    if (e.target.value * 1 <= 100) {
                      setSfx((e.target.value * 1) / 100)
                    }
                  }}
                />
              </div>
            </div>
            <div className="h-[2px] bg-accent-1 w-11/12"></div>
            <div className="flex flex-col w-full items-stretch justify-items-stretch bottom-4 p-2">
              <p className="text-xl text-white text-center">Controls</p>
              <div className="flex flex-row items-center justify-items-center py-[2px]">
                <p className="text-base text-white grow">Row One</p>
                <input
                  type="text"
                  className="appearance-none text-center inline-block align-middle w-[50px] p-[2px] text-base ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
                  defaultValue={controls.row1}
                  onClickCapture={(e: any) => (e.target.value = '')}
                  onBlur={(e) => {
                    if (e.target.value === '') e.target.value = controls.row1
                  }}
                  maxLength={1}
                  onChange={(e) =>
                    setControls({ ...controls, row1: e.target.value })
                  }
                ></input>
              </div>
              <div className="flex flex-row items-center justify-items-center py-[2px]">
                <p className="text-base text-white grow">Row Two</p>
                <input
                  type="text"
                  className="appearance-none text-center inline-block align-middle w-[50px] p-[2px] text-base ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
                  defaultValue={controls.row2}
                  onClickCapture={(e: any) => (e.target.value = '')}
                  onBlur={(e) => {
                    if (e.target.value === '') e.target.value = controls.row2
                  }}
                  maxLength={1}
                  onChange={(e) =>
                    setControls({
                      ...controls,
                      row2: e.target.value.toLowerCase(),
                    })
                  }
                ></input>
              </div>
              <div className="flex flex-row items-center justify-items-center py-[2px]">
                <p className="text-base text-white grow">Row Three</p>
                <input
                  type="text"
                  className="appearance-none text-center inline-block align-middle w-[50px] p-[2px] text-base ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
                  defaultValue={controls.row3}
                  onClickCapture={(e: any) => (e.target.value = '')}
                  onBlur={(e) => {
                    if (e.target.value === '') e.target.value = controls.row3
                  }}
                  maxLength={1}
                  onChange={(e) =>
                    setControls({
                      ...controls,
                      row3: e.target.value.toLowerCase(),
                    })
                  }
                ></input>
              </div>
              <div className="flex flex-row items-center justify-items-center py-[2px]">
                <p className="text-base text-white grow">Row Four</p>
                <input
                  type="text"
                  className="appearance-none text-center inline-block align-middle w-[50px] p-[2px] text-base ml-2 bg-white rounded-full shadow-md focus:outline-none focus:ring-0"
                  defaultValue={controls.row4}
                  onClickCapture={(e: any) => (e.target.value = '')}
                  onBlur={(e) => {
                    if (e.target.value === '') e.target.value = controls.row4
                  }}
                  maxLength={1}
                  onChange={(e) =>
                    setControls({
                      ...controls,
                      row4: e.target.value.toLowerCase(),
                    })
                  }
                ></input>
              </div>
            </div>

            <Gear
              className="absolute -top-4 -right-4 bg-accent-1 rounded-lg cursor-pointer"
              height={32}
              width={32}
              fill="rgb(34, 29, 37, .9)"
              onClick={() => toggleBigMenu(!bigMenu)}
            />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {bigMenu && (
          <div
            className="absolute z-30 bottom-0 left-0 w-screen h-screen flex items-center justify-center"
            onClick={() => {
              if (out.out) {
                toggleBigMenu(false)
                reset()
              }
            }}
          >
            <motion.div
              className="h-5/6 w-5/6 bg-base-dark border-2 border-accent-1 rounded-3xl relative pointer-events-auto"
              animate={{ scale: 1, opacity: 1 }}
              initial={{ scale: 0, opacity: 0 }}
              exit={{ scale: 0, opacity: 0 }}
              onPointerLeave={toggleOut}
              onPointerEnter={reset}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <Close
                className="absolute -top-4 -right-4 cursor-pointer"
                height={32}
                width={32}
                fill="#cb71ff"
                onClick={() => toggleBigMenu(!bigMenu)}
              />
              <div className="flex flex-row h-full">
                <div className="w-40 m-6 space-y-4 flex flex-col justify-center items-center text-white text-lg relative">
                  <motion.button
                    className="absolute top-2 border-2 border-accent-1 w-full px-8 p-2 rounded-full"
                    onClick={SaveSettings}
                    whileHover={{ scale: [1.1, 1.1], rotate: [0, -1, 1] }}
                    animate={{ scale: [1, 1], rotate: [0, 0] }}
                    whileTap={{ scale: [0.9, 0.9], rotate: [10, -10, 0] }}
                    transition={{
                      ease: 'easeInOut',
                      repeat: Infinity,
                      repeatType: 'reverse',
                    }}
                  >
                    Save
                  </motion.button>
                  <button
                    className={`w-full bg-transparent duration-300 ${
                      page === 'Appearance' ? 'text-accent-1' : ''
                    }`}
                    onClick={() => {
                      setPage('Appearance')
                    }}
                  >
                    Appearance
                  </button>
                  <div className="w-full border border-accent-1" />
                  <button
                    className={`w-full bg-transparent duration-300 ${
                      page === 'Controls' ? 'text-accent-1' : ''
                    }`}
                    onClick={() => {
                      setPage('Controls')
                    }}
                  >
                    Controls
                  </button>
                  <div className="w-full border border-accent-1" />
                  <button
                    className={`w-full bg-transparent duration-300 ${
                      page === 'Audio' ? 'text-accent-1' : ''
                    }`}
                    onClick={() => {
                      setPage('Audio')
                    }}
                  >
                    Audio
                  </button>
                  <div className="w-full border border-accent-1" />
                  <button
                    className={`w-full bg-transparent duration-300 ${
                      page === 'Video' ? 'text-accent-1' : ''
                    }`}
                    onClick={() => {
                      setPage('Video')
                    }}
                  >
                    Video
                  </button>
                </div>
                <div className="h-full border border-accent-1" />
                <div className="relative w-full h-full">
                  <SelectedPage page={page} />
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

export default memo(Settings, (prevProps, nextProps) => {
  console.log(prevProps.mapID, nextProps.mapID)
  return prevProps.mapID === nextProps.mapID
})
