import {toNumber} from 'lodash';
import type * as Schemas from '../../../schema/types'

export const getAccuracy = (mapData: Schemas.MapData) => {
  const missed =
    mapData.notes.length - mapData.notes.filter((note) => note.hit).length
  return toNumber(
    (
      100 -
      (missed / (missed + mapData.notes.filter((n) => n.hit).length)) * 100
    ).toFixed(2),
  ).toString()
}

export const getScore = (mapData: Schemas.MapData) => {
  const acc = getAccuracy(mapData)
  const formula =
    mapData.notes.filter((n) => n.privateData.hitMessage === 'Perfect').length *
      100 +
    mapData.notes.filter((n) => n.privateData.hitMessage === 'Great').length *
      50 +
    mapData.notes.filter((n) => n.privateData.hitMessage === 'Ok').length * 25

  return Math.round(formula * (Number(acc) / 100))
}