import { io } from "socket.io-client";
const socket = io('https://api.wayofthat.com');

socket.on('connect', () => {
  console.log('connected');
})

socket.on('disconnect', () => {
  console.log('disconnected');
})

export default socket;

