import Announcements from './landing/announcements'
import LandingStage from "./landing/landingStage";
import { FallingNotes } from "./landing/fallingNotesThing";

import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom'

export default function LandingPage () {
  const [startAnimation, setStartAnimation] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <title>Way Of That tutorial page</title>
      <div className="h-screen w-screen flex flex-row items-center justify-start fixed top-0 left-0 -z-20 overflow-hidden">
        <FallingNotes mapID={"1a08865f-e6aa-402e-8ed2-c5b8f070953b"} playing={false} setMapID={(e) => console.log(e)} />
      </div>
      <div className="min-h-screen min-w-full overflow-x-hidden">

        <div className="h-screen w-screen flex flex-row items-center justify-center md:justify-start">
          <Announcements />
        </div>

        <div className="h-screen w-screen overflow-hidden relative">
          <div className="text-white w-screen flex flex-col items-center ">
            <h1 className="font-bold text-center text-2xl mt-10 bg-base-dark p-4 rounded-3xl">
              How to play
            </h1>
            <motion.ul className="list-disc list-inside text-lg space-y-2 bg-base-dark p-4 rounded-3xl">
              <motion.li>Use <span className='px-2 border-solid border-2 rounded-lg border-accent-1 drop-shadow-md'>A</span>, <span className='px-2 border-solid border-2 rounded-lg border-accent-1 drop-shadow-md' >S</span>, <span className='px-2 border-solid border-2 rounded-lg border-accent-1 drop-shadow-md' >L</span>, <span className='px-2 border-solid border-2 rounded-lg border-accent-1 drop-shadow-md' >;</span> to click the incoming notes</motion.li>
              <motion.li>Click the notes when they cross the HitBar</motion.li>
              <motion.li>Now strive to beat your friends scores!</motion.li>
            </motion.ul>
            <motion.button whileTap={{
              scale: 0.99,
            }} 
            whileHover={{
              scale: 1.01,
            }}
            animate={{scale:1}} onClick={() => setStartAnimation(!startAnimation)} className='mt-2 bg-base-dark p-2 w-2/3 rounded-full border-2 border-accent-1 select-none'>Demo</motion.button>
          </div>
          <div className="absolute top-0 left-0 w-screen h-screen -z-10">
            <LandingStage mapID={"1a08865f-e6aa-402e-8ed2-c5b8f070953b"} playing={startAnimation} setMapID={() => {}} />
          </div>
        </div>
        <div className='w-screen p-5 h-[50vh] bg-base-dark'>
          <div className='flex-col flex md:items-center justify-center'>
          <div className='text-white flex flex-col items-center'>
            <button onClick={() => navigate('/3d')} className="px-10 py-5 m-2 rounded-3xl border-accent-1 border-2 text-3xl">Play Now!</button>
          </div>
           
          <p className='text-white md:px-[10vw]'>
            <span className='text-3xl font-bold'>Note</span> that the game is still in development. <br /><br />
            <p>So far the game has had a single <abbr title='A person who spends too much time working on weird projects - said Chance#0002 3:18am 9/6/22'>developer</abbr> and this is the reason the art is bad. The future plans is to make a well rounded multiplayer aspect with head to head gameplay</p>
          </p>
          </div>
        </div>
        <div className='w-screen min-h-[1vh] bg-accent-1 text-xl text-white flex flex-row flex-wrap justify-evenly gap-2'>
            <a href='https://discord.gg/bzU5BwTzcF'>Discord</a>
            <a href='https://github.com/wayofthat'>Github</a>
            <a href='https://twitter.com/wayofthat'>Twitter</a>
        </div>
      </div>
    </>
  )
}