import axios from "axios";

export function getMapInfo(mapID: any) {
  const mapData = new Promise<void>(async (resolve, reject) => {
    const getMap = await axios(`https://api.wayofthat.com/api/v1/${mapID}/data`)
    resolve(getMap.data)
  })

  const mapInfo = new Promise<void>(async (resolve, reject) => {
    const getMap = await axios(`https://api.wayofthat.com/api/v1/${mapID}/info`)
    resolve(getMap.data)
  })

  return {
    mapData,
    mapInfo,
    audio: new Audio(`https://api.wayofthat.com/api/v1/songs/${mapID}`),
  }
}