export const globalVariables = {
  noteHit: [],
  noteText:'',
}

export const args = {
  maxPitch: 0.0,
  maxRoll: 0.0,
  maxYaw: 0.02,
  pitchFrequency: 0.0,
  rollFrequency: 0.0,
  yawFrequency: 0.8,
}

export default globalVariables;