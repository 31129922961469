import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import './index.css';
import TwoD from './TwoD';
import ThreeD from './pages/map';
import reportWebVitals from './reportWebVitals';
import LandingPage from './pages/landing';

import { createRoot } from 'react-dom/client';
import './handlers/socket';

const container = document.getElementById('root');
const root = createRoot(container!)

root.render(
  <>
  <title>Way Of That</title>
  <meta content="Way Of That" property="og:title" />
  <meta content="Come hit notes instead of your wife" property="og:description" />
  <meta content="https://cdn.discordapp.com/attachments/939334796822343680/941145876380909588/way_of_that_circle_logo_no_background.png" property="og:url" />
  <meta content="https://media.discordapp.net/attachments/939334796822343680/941153405936738414/opera_VDkJ7DGZJn.png" property="og:image" />
  <meta content="#cb71ff" data-react-helmet="true" name="theme-color" />
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/"   element={<LandingPage/>} />
        <Route path="/2d" element={<TwoD />} />
        <Route path="/3d" element={<ThreeD />} />
        <Route
          path="*"
          element={
            <main>
              <div className='flex flex-row items-center justify-center w-screen h-screen bg-base-dark text-white'>
                <p>There's nothing here!</p>
              </div>
            </main>
          }
        />
      </Routes>
    </Router>
  </React.StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
