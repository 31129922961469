import { Physics, useBox } from '@react-three/cannon'
import { Canvas, useFrame } from '@react-three/fiber'
import { id } from 'date-fns/locale'
import React, { useEffect, useMemo, useRef } from 'react'
import { Mesh, BufferGeometry } from 'three';
import Camera2 from '../map/functions/camera'

function CreateLotsOfData({ scale = 2, number = 100, multiplier = 2 }) {
  const data = []
  for (let index = 0; index < number; index++) {
    data.push([
      0,
      -(multiplier * (Math.random() * scale)),
      -(multiplier * (Math.random() * scale)),
    ])
  }

  console.log(data)
  return data
}

function LoadSphere({data}) {
  const [ref, api] = useBox(() => ({ mass: .1, velocity: [0, Math.random(), 0], position: [data[0], data[1], data[2]-2] }))

  return (
    <mesh ref={ref as React.RefObject<Mesh<BufferGeometry>>}>
      <sphereGeometry attach="geometry" args={[.5, 32, 32]} />
      <meshStandardMaterial attach="material" color={'#fff'} />
    </mesh>
  )
}

function LoadBox({ data }) {
  const [ref, api] = useBox(() => ({ mass: 0, velocity: [0, 0, 0], position: data }))

  return (
    <mesh ref={ref as React.RefObject<Mesh<BufferGeometry>>}>
      <boxGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial attach="material" color={'#fff'} />
    </mesh>
  )
}

const scale = 20,
  number = 200,
  multiplier = 4

export function FallingNotes(props:any) {
  const blockData = useMemo(() => CreateLotsOfData({ scale, number, multiplier }), [])

  return (
    <>
      <React.Suspense fallback={<p>Loading...</p>}>
        <Canvas className="w-full h-full bg-base-dark">
          <Camera2
            playing={true}
            settings={{
              x: 0,
              y: 0,
              z: 30,
              lobby: {
                x: 30,
                y: 30,
                z: 30,
              },
              fov: 40,
              zoom: 1,
            }}
          />

          <ambientLight />

          {blockData && <Physics 
            gravity={[0, 0, 1]}
          >
            <group
              rotation={[Math.PI / 2, 0, 0]}
              position={[
                0,
                -((scale * multiplier) / 2),
                (scale * multiplier) / 2,
              ]}
            >
              {blockData.map((d) => (<LoadBox data={d} />))}
              {blockData.map((d) => (<LoadSphere data={d} />))}
            </group>
          </Physics>}
        </Canvas>
      </React.Suspense>
    </>
  )
}
