import { useEffect, useState, memo, useMemo, useRef, Suspense, useLayoutEffect } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { BufferGeometry, Color, Mesh, Vector3 } from 'three'

import { timeConvert, rowTime } from '../../utils/conversions'
import { useGLTF, useAnimations } from '@react-three/drei'

import React from 'react'
import _ from 'lodash'

import axios from 'axios'
import type * as Schemas from '../../schema/types'
import Camera2 from '../map/functions/camera'

import {
  Physics,
  usePlane,
  useHeightfield,
  useSphere,
} from '@react-three/cannon'
import { generateHeightmap, Heightfield } from './heightFeild'

let map_Info: Schemas.MapInfo | undefined
let audio: React.MutableRefObject<HTMLAudioElement> | undefined

const getCorrectRow = (row: string) => {
  return {
    row1: -6,
    row2: -2,
    row3: 2,
    row4: 6,
  }[row]
}

const RenderNotes = ({
  audio,
  mapData,
}: {
  audio: React.MutableRefObject<HTMLAudioElement> | undefined
  mapData: Schemas.MapData | undefined
}) => {
  const groupRef = useRef<any>(null)

  const noteArray = useMemo(() => {
    if (!audio || !mapData || !audio) return []
    const _noteArray: JSX.Element[] = []
    console.log('refresh notes')
    mapData.notes.forEach((note: Schemas.Note, i) => {
      if (!map_Info || !audio) return
      _noteArray.push(
        <mesh
          key={i}
          position={[
            getCorrectRow(`row${note.row}`) || 2,
            rowTime({ time: note.time, bpm: map_Info.beatsPerMinute }),
            0,
          ]}
        >
          <sphereBufferGeometry args={[1.5, 32, 32]} />
          <meshStandardMaterial attach="material" color="#fff" />
        </mesh>,
      )
    })
    return _noteArray
  }, [mapData])

  useFrame(() => {
    if (!audio || !mapData || !map_Info) return
    if (!groupRef.current) return
    const positon = timeConvert({
      time: audio.current.currentTime,
      bpm: map_Info.beatsPerMinute,
    })
    const noteOffset = 3 // Args first pos * 2
    if (positon !== 0) groupRef.current.position.y = noteOffset - positon
  })

  return (
    <group position={[0, 0, 0]} ref={groupRef}>
      {noteArray.map((n) => {
        return n
      })}
    </group>
  )
}

const HitBar = () => {
  return (
    <mesh position-y={0}>
      <boxBufferGeometry args={[13, 1, 2.1]} />
      <meshStandardMaterial
        attach="material"
        opacity={0.5}
        transparent
        color="rgb(235, 117, 0)"
      />
    </mesh>
  )
}

function Plane(props) {
  const [ref] = usePlane(() => ({ rotation: [-Math.PI / 2, 0, 0], ...props }))
  return (
    <mesh castShadow receiveShadow ref={ref as React.RefObject<Mesh<BufferGeometry>>} visible={false}>
      <planeBufferGeometry args={[1000, 1000]} />
      <meshStandardMaterial attach="material" color="#221d25" />
    </mesh>
  )
}

function Sphere(props: {
  [key: string]: any
  key: number
  noteInformation: Schemas.MapData['notes'][0]
}) {
  const { noteInformation, key } = props
  const [ref, api] = useSphere(() => ({ mass: 1, ...props }))
  const shpeareRef = useRef<any>(null)
  const [didMassChange, setMassChange] = useState(false)
  const [colorSet, setColorSet] = useState(false)

  useFrame(({ clock }) => {
    if (!ref.current || !map_Info || !audio) return

    
    // Check if the sphere is still being played in the song and has not passed the yellow box + 10
    const bpm = timeConvert({
      time: audio.current.currentTime,
      bpm: map_Info.beatsPerMinute,
    })
    if (
      bpm -
        rowTime({ time: noteInformation.time, bpm: map_Info.beatsPerMinute }) >=
        0 &&
      !colorSet
    ) {
      if (shpeareRef.current) {
        setColorSet(true)
        console.dir(shpeareRef.current)
        shpeareRef.current.color =
          Math.random() > 0.1 ? new Color('#00ff2f') : new Color('#ff0022')
      }
    }

    if (
      bpm -
        rowTime({ time: noteInformation.time, bpm: map_Info.beatsPerMinute }) >=
      7
    ) {
      if (!didMassChange) {
        setMassChange(true)
        api.mass.set(1)
        /*api.velocity.set(
          getCorrectRow(`row${noteInformation.row}`),
          0,
          map_Info.beatsPerMinute / 2,
        )*/

        
        api.linearDamping.set(0.1)

        api.velocity.copy(
          new Vector3(
            getCorrectRow(`row${noteInformation.row}`) * Math.random(),
            0,
            (map_Info.beatsPerMinute / 2) * Math.random(),
          ),
        )
      } else {
        //api.velocity.copy(new Vector3(0, 0, 0))
      }
    } else {
      api.mass.set(0)
      api.position.set(
        getCorrectRow(`row${noteInformation.row}`) || 2,
        1,
        bpm -
          rowTime({ time: noteInformation.time, bpm: map_Info.beatsPerMinute }),
      )
      //ref.current.position.x = getCorrectRow(`row${noteInformation.row}`)
      //ref.current.position.y = 1
      //ref.current.position.z = bpm - rowTime({ time: noteInformation.time, bpm: map_Info.beatsPerMinute })
    }

    // if the sphere is still being played in the song, then update the position of the sphere

    // if the sphere has passed the yellow box + 10, then do not update the position of the sphere anymore
  })

  return (
    <Suspense fallback={null}>
      <mesh castShadow receiveShadow ref={ref as React.RefObject<Mesh<BufferGeometry>>}>
        <sphereBufferGeometry />
        <meshStandardMaterial attach="material" color="#fff" ref={shpeareRef} />
      </mesh>
    </Suspense>
  )
}

const scale = 1000

const PhysicsNotes = ({ mapData, mapInfo }) => {
  return (
    <Suspense fallback={''}>
      <Physics> {
        /* <Heightfield
          elementSize={(scale * 1) / 128}
          heights={generateHeightmap({
            height: 128,
            number: 100,
            scale: 3,
            width: 128,
          })}
          position={[-scale / 2, -4, scale / 2]}
          rotation={[-Math.PI / 2, 0, 0]}
        />*/
        }
        
        <Plane position={[0, -5, 0]} />
        {mapData.notes.map((note, index) => (
          <Sphere key={index} noteInformation={note} position={[0, 0, 0]} />
        ))}
      </Physics>
    </Suspense>
  )
}

function LandingStage(caller: {
  mapID: string | undefined
  playing: boolean
  setMapID: React.Dispatch<React.SetStateAction<string | undefined>>
}) {
  const id = caller.mapID
  const [mapInfo, setMapInfo] = useState<Schemas.MapInfo | undefined>()
  const [mapData, setMapData] = useState<Schemas.MapData | undefined>()
  const [audioLoaded, setAudioLoaded] = useState<boolean>(false)
  const audioRef = useRef<HTMLAudioElement | undefined>()
  //const [loaded, setLoaded] = states.loaded;
  //const [zoom, setZoom] = states.zoom;

  useEffect(() => {
    if (!id) return

    new Promise(async (resolve) => {
      const mapData = new Promise<void>(async (resolve, reject) => {
        const getMap = await axios(
          `https://api.wayofthat.com/api/v1/${id}/data`,
        )
        setMapData(getMap.data)
        resolve(getMap.data)
      })

      const mapInfo = new Promise<void>(async (resolve, reject) => {
        const getMap = await axios(
          `https://api.wayofthat.com/api/v1/${id}/info`,
        )
        setMapInfo(getMap.data)
        map_Info = getMap.data
        resolve(getMap.data)
      })

      const getAudio = new Promise<void>(async (resolve, reject) => {
        const getAudio = await axios(
          `https://api.wayofthat.com/api/v1/${id}/info/song.mp3`,
        )
        resolve(getAudio.data)
      })

      await Promise.all([mapData, mapInfo])
    })
  }, [])

  useEffect(() => {
    console.dir(audioRef)
    if (!audioRef.current) return
    audio = audioRef
    audioRef.current.volume = 0.01
    if (caller.playing){ 
      audioRef.current.play()
    }
  }, [audioRef.current, audioLoaded, caller.playing])

  return (
    <>
      <React.Suspense fallback={<p>Loading...</p>}>
        {id && (
          <audio
            ref={audioRef}
            src={`https://api.wayofthat.com/api/v1/${id}/info/song.mp3`}
            onCanPlay={() => setAudioLoaded(true)}
          ></audio>
        )}
        <Canvas className="w-full h-full">
          <Camera2
            playing={caller.playing}
            settings={{
              x: 20,
              y: 10,
              z: 0,
              lobby: {
                x: 30,
                y: 30,
                z: 30,
              },
              fov: 90,
              zoom: 1,
            }}
          />

          <ambientLight />

          <group rotation={[Math.PI / 2, 0, 0]}>
            <group position={[6, -50, 0]}>
              <mesh position={[0, 55, 0]}>
                <sphereBufferGeometry args={[1, 32, 32]} />
                <meshStandardMaterial attach="material" color="#cb71ff" />
              </mesh>
              <mesh>
                <cylinderBufferGeometry args={[1, 1, 110, 100]} />
                <meshStandardMaterial attach="material" color="#cb71ff" />
              </mesh>
              <mesh position={[0, -55, 0]}>
                <sphereBufferGeometry args={[1, 32, 32]} />
                <meshStandardMaterial attach="material" color="#cb71ff" />
              </mesh>
            </group>

            <group position={[2, -50, 0]}>
              <mesh position={[0, 55, 0]}>
                <sphereBufferGeometry args={[1, 32, 32]} />
                <meshStandardMaterial attach="material" color="#fff" />
              </mesh>
              <mesh>
                <cylinderBufferGeometry args={[1, 1, 110, 100]} />
                <meshStandardMaterial attach="material" color="#fff" />
              </mesh>
              <mesh position={[0, -55, 0]}>
                <sphereBufferGeometry args={[1, 32, 32]} />
                <meshStandardMaterial attach="material" color="#fff" />
              </mesh>
            </group>

            <group position={[-2, -50, 0]}>
              <mesh position={[0, 55, 0]}>
                <sphereBufferGeometry args={[1, 32, 32]} />
                <meshStandardMaterial attach="material" color="#cb71ff" />
              </mesh>
              <mesh>
                <cylinderBufferGeometry args={[1, 1, 110, 100]} />
                <meshStandardMaterial attach="material" color="#cb71ff" />
              </mesh>
              <mesh position={[0, -55, 0]}>
                <sphereBufferGeometry args={[1, 32, 32]} />
                <meshStandardMaterial attach="material" color="#cb71ff" />
              </mesh>
            </group>

            <group position={[-6, -50, 0]}>
              <mesh position={[0, 55, 0]}>
                <sphereBufferGeometry args={[1, 32, 32]} />
                <meshStandardMaterial attach="material" color="#fff" />
              </mesh>
              <mesh>
                <cylinderBufferGeometry args={[1, 1, 110, 100]} />
                <meshStandardMaterial attach="material" color="#fff" />
              </mesh>
              <mesh position={[0, -55, 0]}>
                <sphereBufferGeometry args={[1, 32, 32]} />
                <meshStandardMaterial attach="material" color="#fff" />
              </mesh>
            </group>

            <HitBar />
          </group>

          {mapData && mapInfo && (
            <PhysicsNotes mapData={mapData} mapInfo={mapInfo} />
          )}
        </Canvas>
      </React.Suspense>
    </>
  )
}

export default LandingStage
