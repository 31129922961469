import { motion } from 'framer-motion'

const ThreeCircle = () => {
  const Circle = () => (
    <svg
      height="10"
      width="10"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
    >
      <circle cx="50" cy="50" r="50" />
    </svg>
  )

  return (
    <>
      <motion.div
        className="text-3xl"
        animate={{
          opacity: [0.5, 1, 1, 1],
          repeatCount: Infinity,
        }}
        transition={{ duration: 1, repeat: Infinity, repeatDelay: 1 }}
      >
        <Circle />
      </motion.div>
      <motion.div
        className="text-3xl align-top"
        animate={{
          opacity: [1, 0.5, 1, 1],
          repeatCount: Infinity,
        }}
        transition={{ duration: 1, repeat: Infinity, repeatDelay: 1 }}
      >
        <Circle />
      </motion.div>
      <motion.div
        className="text-3xl"
        animate={{
          //scale: [1, 1.5, 1],
          opacity: [1, 1, 0.5, 1],
        }}
        transition={{ duration: 1, repeat: Infinity, repeatDelay: 1 }}
      >
        <Circle />
      </motion.div>
    </>
  )
}

export default ThreeCircle /*function Landing() {
  // Make loading screen with loading animation that has three dots that highlight in a wave pattern

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center bg-orange-400 text-gray-800">
      <h1 className="text-4xl font-bold text-center">Waiting for game...</h1>
      <motion.div
        animate={{
          rotate: 360,
          repeatCount: Infinity,
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatDelay: 1,
          delay: 1.2,
        }}
        className="flex flex-row gap-4 items-center justify-center h-20"
      >
        <ThreeCircle />
      </motion.div>
    </div>
  )
}*/

export { ThreeCircle }