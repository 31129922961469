import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import { ReactComponent as Pause } from '../assets/pause.svg';
import { ReactComponent as Play } from '../assets/play.svg';
import { ReactComponent as VolMute } from '../assets/volume-mute.svg';
import { ReactComponent as VolPlay } from '../assets/volume-up.svg';

import type * as Schemas from '../schema/types';
import PlayAudio from '../elements/playAudio';
import LoadMap from '../componets/loadMap';


function Player({ id }: any): JSX.Element {
    const [mapInfo, setMapInfo] = useState<Schemas.MapInfo>();
    const [mapData, setDataInfo] = useState<Schemas.MapData>();
    const [showDebug, setShowDebug] = useState<boolean>(true);
    const [mapID, setMapID] = useState<string>(id);

    useEffect(() => {
        new Promise(async resolve => {
            const getMap = await axios(`https://api.wayofthat.com/api/v1/${mapID}/info`)
            setMapInfo(getMap.data as Schemas.MapInfo);
            resolve(getMap.data);
        })
    }, []);

    useEffect(() => {
        if (!mapInfo) return;
        new Promise(async resolve => {
            const getMap = await axios(`https://api.wayofthat.com/api/v1/${mapID}/data`);
            setDataInfo(getMap.data as Schemas.MapData);
            resolve(getMap.data);
        })
    }, [mapInfo]);


    const [playing, toggle, [volume, changeVol, muted, muteVol, audioCurrentTime, currentAudio]] = PlayAudio(`https://api.wayofthat.com/api/v1/songs/${mapID}`);

    return (
        <div className='flex flex-col h-screen justify-center'>
            {
                <div className='flex flex-row justify-center items-center'>
                    {mapInfo ?
                        <div className='flex flex-col absolute z-0 top-4 left-4 items-stretch shadow-md'>
                            {mapInfo.coverImageURL ? <img src={mapInfo.coverImageURL} alt='cover' className='w-[217px] rounded-t-lg border-t-2 border-l-2 border-r-2 border-accent-1' /> : null}
                            <div className={`bg-base-dark ${mapInfo.coverImageURL ? "border-b-2 border-l-2 border-r-2 rounded-b-lg" : "border-2 rounded-lg"} border-accent-1 shadow-lg p-2`}>
                                <p className='text-white text-center text-xl max-w-[190px]'>{mapInfo.title}</p>
                                <p className='text-gray-300 text-center text-md max-w-[190px]'>{mapInfo.author}</p>
                            </div>
                        </div>
                        : null
                    }

                    {showDebug ?
                        <div className='flex flex-col absolute top-4 right-4 p-2 rounded-md shadow-lg bg-base-dark border-2 border-accent-1'>
                            <p className='text-white'>Version: {mapInfo?.version}</p>
                            <p className='text-white'>{`Time: ${audioCurrentTime.toFixed(3)} / ${Math.round(currentAudio.duration)}`}</p>
                            <p className='text-white'>BPM: {mapInfo?.beatsPerMinute} | {((audioCurrentTime / (60 / (mapInfo?.beatsPerMinute || 1)))).toFixed(2)}</p>
                            <p className='text-white'>ID: {mapInfo?._id}</p>
                        </div>
                        : null
                    }

                    {mapInfo && mapData ? <LoadMap audio={{ playing, toggle, volume, changeVol, muted, muteVol, audioCurrentTime }} mapInfo={mapInfo} mapData={mapData} /> : null}

                    <div className='absolute flex flex-row items-center left-3 bottom-4 bg-accent-1 w-fit pr-2 shadow-md rounded-md'>
                        <button className='flex flex-row p-2' onClick={() => toggle()}>{playing ? <Pause fill="#221d25" /> : <Play fill="#221d25" />}</button>
                        <input
                            className='appearance-none slider w-full h-2 p-0 bg-white rounded-full shadow-md focus:outline-none focus:ring-0'
                            type="range"
                            value={volume}
                            min={0}
                            max={1}
                            step={0.01}
                            onChange={(e: any) => changeVol(e.target.value)}
                        />
                        <button className='flex flex-row p-2' onClick={() => muteVol()}>{muted ? <VolMute fill="#221d25" /> : <VolPlay fill="#221d25" />}</button>
                    </div>

                    <input
                        type="range"
                        className='appearance-none oslider absolute h-2 bottom-0 right-0 w-screen border-0 outline-none'
                        value={audioCurrentTime}
                        min={0}
                        max={currentAudio.duration || 0}
                        step={0.01}
                        style={{ background: `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentAudio.duration ? `${(audioCurrentTime / currentAudio.duration) * 100}%` : '0%'}, #cb71ff), color-stop(${currentAudio.duration ? `${(audioCurrentTime / currentAudio.duration) * 100}%` : '0%'}, #221d25))` }}
                        readOnly={true}
                    />
                </div>
            }
        </div>
    );
}

export default Player;