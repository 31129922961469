import { useEffect, useMemo, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ThreeCircle from "../../componets/loading";
import {useInterval} from 'usehooks-ts';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)

function ParseTextToHTML(text:string) {
  /*
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");
  return doc.body.innerHTML;*/

  let html = text;
  const regex = /(?:\[.+\]\(.+\))/gm
  const htmlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm
  
  if (html.match(regex)) {
    html.match(regex).forEach((match) => {
      const splited = match.split("](");
      const link = splited[1].replaceAll(")", "");
      const text = splited[0].replaceAll("[", "");

      if (htmlRegex.test(link)) {
        let linkHtml = ``
        text.split(" ").forEach((word, index) => {
          linkHtml += `<a class="text-accent-1 whitespace-pre" href="${link}">${index >= 1 ? " " : ''}${word}</a>`;
        })
        html = html.replaceAll(match, `${linkHtml}`);
      }
    })
  }

  const boldRegex = /\*{2}.+?\*{2}/gm
  if (html.match(boldRegex)) {
    html.match(boldRegex).forEach((match) => {
      const text = match.replaceAll("**", "");
      html = html.replaceAll(match, `<b>${text}</b>`);
    })
  }

  const underlineRegex = /\_{2}.+?\_{2}/gm
  if (html.match(underlineRegex)) {
    html.match(underlineRegex).forEach((match) => {
      const text = match.replaceAll("__", "");
      html = html.replaceAll(match, `<u>${text}</u>`);
    })
  }

  const italicRegex = /\*{1}.+?\*{1}/gm
  if (html.match(italicRegex)) {
    html.match(italicRegex).forEach((match) => {
      const text = match.replaceAll("*", "");
      html = html.replaceAll(match, `<i>${text}</i>`);
    })
  }

  const strikethroughRegex = /\~{2}.+?\~{2}/gm
  if (html.match(strikethroughRegex)) {
    html.match(strikethroughRegex).forEach((match) => {
      const text = match.replaceAll("~~", "");
      html = html.replaceAll(match, `<s>${text}</s>`);
    })
  }

  
  // Parse Html
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return <p className="whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: doc.body.innerHTML}}/>
}

function LoadAnnouncements ({announcements}) {
  const textRef = useRef<any>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const [currentSelection, setCurrentSelection] = useState(0);
  const [corectWidth, setCorectWidth] = useState(0);
  const [dontChange, setDontChange] = useState(false);

  const timeAgo = useMemo(() => new TimeAgo('en-US'), []);

  useEffect(() => {
    setCorectWidth(textRef.current?.clientWidth)
  }, [textRef.current, textRef.current?.offsetWidth]);

  useInterval(() => {
    if (dontChange) setDontChange(false);
    else {
      if (currentSelection >= announcements.length - 1) {
        setCurrentSelection(0)
      } else setCurrentSelection(currentSelection + 1);
    }
  } , 10000);

  const Circle = (props) => (
    <svg
      height="10"
      width="10"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
      {...props}
    >
      <circle cx="50" cy="50" r="50" />
    </svg>
  )


  return <div className="flex flex-col relative h-full bg-base-dark">
    <div id='announcementsImages' className="h-3/4 overflow-hidden relative" ref={imageRef}>
      <AnimatePresence presenceAffectsLayout initial={false}>
        {/* loading the stupid dots */}
        <div className="absolute bottom-4 w-full flex flex-row items-center justify-center gap-4 z-10">
          {announcements.map((announcement, index) => 
            <motion.div onClick={() => {setCurrentSelection(index); setDontChange(true)}}><Circle fill={currentSelection === index ? '#a1a1a1' : "#fff"}/></motion.div>
          )}
        </div>

        {/* loading the actuall image here */}
        {announcements.map((announcement, index) => currentSelection === index ?           
            <motion.img 
            id={announcement._id}
            key={announcement._id}
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 1 }}
            className="h-full object-cover object-center w-full absolute top-0 left-0" src={announcement.image} alt={announcement.title} /> : null
        )}
      </AnimatePresence>
    </div>
    <div className="w-full h-[2px] bg-accent-1" ref={textRef}/>
    <div id='announcementsText' className="h-1/4 text-white overflow-hidden flex flex-col flex-wrap relative">
      <AnimatePresence presenceAffectsLayout initial={false}>
        {announcements.map((announcement, index) => currentSelection === index ?
          <motion.div 
          id={announcement._id}
          key={announcement._id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className="h-full absolute top-0 left-0 w-full">
            <h1 className="text-center w-full font-bold inline-block">{announcement.title}</h1>
            <div className="p-2">
              <p className=" w-full overflow-ellipsis overflow-hidden line-clamp-2 md:line-clamp-3 lg:line-clamp-6">{ParseTextToHTML(announcement.body)}</p>
            </div>
            <div className="h-6"></div>
            <p className="absolute bottom-2 right-2">{timeAgo.format(new Date(announcement.date))}</p>
          </motion.div> : null
        )}
      </AnimatePresence>
    </div>
  </div>
}

export default function Announcements() {
  const [Announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAnnouncements = async () => {
      const response = await fetch(
        "https://api.wayofthat.com/api/v1/announcements"
      );
      const data = await response.json();
      setAnnouncements(data);
      setLoading(false);
    }
    getAnnouncements();
  }, [])

  return (
    <div className="border-2 border-accent-1 overflow-hidden rounded-2xl md:ml-4 w-full md:w-1/4 md:min-w-[250px] lg:min-w-[300px] max-w-[300px] h-3/4 relative">
      {loading ? 
      <div className="flex flex-row items-center justify-center h-full">
        <ThreeCircle/>
      </div> :
      <LoadAnnouncements announcements={Announcements}/>}
    </div>
  )
}