import { CameraShake, MeshReflectorMaterial, Text } from "@react-three/drei";
import { useFrame, Canvas } from "@react-three/fiber";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useState, useMemo, useEffect, useRef } from "react";
import { Color } from "three";
import { timeConvert, rowTime, settings as mathSettings } from "../../utils/conversions";
import DisplayError from "../../componets/displayError";
import useEventListener from "../../elements/useEventListener";
import { Settings } from "../../schema/types";

import type * as Schemas from '../../schema/types';

import defaultSettings from "./defualtSettings";
import { getMapInfo } from "./functions/getMapInfo";

import globalVariables, { args } from "./globalVariables";
import { Bloom } from "./functions/renders";
import playSound, { LoadTapNote } from "./functions/playSound";
import GetLastHit from "./functions/getLastHit";
import Camera2 from "./functions/camera";

import SettingsComponent from '../../componets/settings';
import { getAccuracy, getScore } from "./functions/score";

const Refsaredumb = ({ mapID, controles }: any) => {
  const mapAudios = useRef<HTMLAudioElement>(
    new Audio(`https://api.wayofthat.com/api/v1/${mapID}/info/song.mp3`),
  );

  useEffect(() => {
    controles(mapAudios.current);
  }, [mapAudios]);

  return null;
};

function Stage(caller: {
  mapID: string | undefined;
  playing: boolean;
  setMapID: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const [error, setError] = useState<{ errors: string[]; }>({
    errors: [],
  });
  const [startCountDown, setStartCountDown] = useState<boolean>(false);
  const [playing2, setPlaying] = useState(false); // should pan out or not
  const [mapData, setMapData] = useState<Schemas.MapData>(); // Map data
  const [mapInfo, setMapInfo] = useState<Schemas.MapInfo>(); // Map info
  const [ended, setEnded] = useState(false);
  const [key, setKey] = useState<any>({
    ArrowUp: false,
    ArrowDown: false,
    Escape: false,
    ' ': false,
    a: false,
    s: false,
    l: false,
    ';': false,
  }); // What keys are currently being held down
  const [settings, setSettings] = useState<Settings>(defaultSettings); // Settings
  const [speed] = useState(20); // The speed of the notes

  const [audo, setAudo] = useState<HTMLAudioElement>(); // Map audio
  const [tapNote, setTapNote] = useState<HTMLAudioElement>(); // Tap note audio

  const audioControles = (fart: HTMLAudioElement) => setAudo(fart);

  function newError(err: string) {
    setError({ errors: [...error.errors, err] });
  }

  useMemo(() => {
    if (!audo) return;
    audo.volume = settings.audio.music * settings.audio.masterVolume;
    setStartCountDown(true);
    audo.onended = () => setEnded(true);
    audo.onplaying = () => setEnded(false);
  }, [audo]);

  useEffect(() => {
    console.log(`Song Ended Display Screen ${ended}`);
    console.log(mapData?.notes);
  }, [ended]);

  useEffect(() => {
    new Promise<void>(async (resolve) => {
      if (!caller.mapID) {
        console.log('Do something since no mapID was passed');
        resolve();
        return;
      }
      const { mapData, mapInfo, audio } = getMapInfo(caller.mapID);
      setMapData(((await mapData) as unknown) as Schemas.MapData);
      setMapInfo(((await mapInfo) as unknown) as Schemas.MapInfo);
      setPlaying(true);
      resolve();
    });
  }, [caller.mapID]);

  function pressRow({ row }: { row: number; }) {
    if (!mapInfo || !mapData || !audo) return;
    let hit = false;
    mapData?.notes.sort((a: any, b: any) => a.time - b.time);
    mapData?.notes.forEach((note: Schemas.Note, index: number) => {
      if (hit || note.hit || note.missed) return;
      if (note.row === row) {
        const doMathOnce = (timeConvert({ time: audo.currentTime, bpm: mapInfo.beatsPerMinute }) - rowTime({ time: note.time, bpm: mapInfo.beatsPerMinute })) / mathSettings.timeInMinute;
        console.log(doMathOnce, timeConvert({ time: audo.currentTime, bpm: mapInfo.beatsPerMinute }), rowTime({ time: note.time, bpm: mapInfo.beatsPerMinute }));
        switch (
        Math.abs(doMathOnce).toFixed(1)
        ) {
          case '0.0':
            globalVariables.noteHit.push('Perfect');

            note.privateData.hitMessage = 'Perfect';
            note.privateData.hitTime = (
              doMathOnce
            ).toFixed(1);
            if (note.type === 2) note.privateData.startHit = true;
            else note.hit = true;
            playSound(tapNote, settings);

            hit = true;
            return;

          case '0.1':
            globalVariables.noteHit.push('Great');

            note.privateData.hitMessage = 'Great';
            note.privateData.hitTime = (
              doMathOnce
            ).toFixed(1);
            if (note.type === 2) note.privateData.startHit = true;
            else note.hit = true;
            playSound(tapNote, settings);

            hit = true;
            return;

          case '0.2':
            globalVariables.noteHit.push('Ok');

            note.privateData.hitMessage = 'Ok';
            note.privateData.hitTime = (
              doMathOnce
            ).toFixed(1);
            if (note.type === 2) note.privateData.startHit = true;
            else note.hit = true;
            playSound(tapNote, settings);

            hit = true;
            return;

          default:
            console.log('Miss', Math.abs(doMathOnce).toFixed(1));
            return;
        }
      }
    });

    if (!hit) {
      globalVariables.noteHit.push('Miss');
    }
  }

  function pressUpRow({ row }: { row: number; }) {
    if (!mapInfo || !mapData || !audo) return;
    let hit = false;
    mapData?.notes.sort((a: any, b: any) => a.time - b.time);
    mapData?.notes.forEach((note: Schemas.Note, index: number) => {
      if (hit || note.hit || note.missed) return;
      if (note.row === row && note.type === 2) {
        const doMathOnce = (timeConvert({ time: audo.currentTime, bpm: mapInfo.beatsPerMinute }) - (rowTime({ time: note['time-end'], bpm: mapInfo.beatsPerMinute }))) / mathSettings.timeInMinute;
        switch (
        Math.abs(
          doMathOnce,
        ).toFixed(1)
        ) {
          case '0.0':
            console.log('Perfect');
            globalVariables.noteHit.push('Perfect');

            note.hit = true;
            if (note.type === 2) note.privateData.endHit = true;

            hit = true;
            return;

          case '0.1':
            console.log('Great');
            globalVariables.noteHit.push('Great');

            note.hit = true;
            if (note.type === 2) note.privateData.endHit = true;

            hit = true;
            return;

          case '0.2':
            console.log('Good');
            globalVariables.noteHit.push('Good');

            note.hit = true;
            if (note.type === 2) note.privateData.endHit = true;

            hit = true;
            return;

          default:
            //console.log('Miss', Math.abs(audo.currentTime / (60 / mapInfo.beatsPerMinute) - note['time-end']).toFixed(1), doMathOnce, note['time-end']);
            return;
        }
      }
    });
  }

  const toggle = () => {
    if (playing2) {
      setPlaying(false);
      setStartCountDown(false);
      audo?.pause();
    } else {
      setPlaying(true);
      setStartCountDown(true);
    }
  };

  const restart = () => {
    if (!audo || !mapData) return;
    setShouldShake(false);
    setPlaying(true);
    setEnded(false);
    audo.currentTime = 0;
    mapData.notes.forEach((note: Schemas.Note) => {
      note.hit = false;
      note.missed = false;
      note.privateData.startHit = false;
      note.privateData.endHit = false;
    });
    globalVariables.noteHit = [];
    globalVariables.noteText = '';
    audo.pause();
    setStartCountDown(true);
  };

  useEventListener('keydown', (event: KeyboardEvent) => {
    // @ts-ignore
    if (event.target?.nodeName === 'INPUT') return;
    if (!audo || !mapData || key[event.key]) return;
    setKey((key: { [x: string]: boolean; }) => {
      key[event.key] = true;
      return key;
    });
    switch (event.key) {
      case ' ':
        setEnded(false);
        setShouldShake(false);
        toggle();
        event.preventDefault();
        break;

      case settings.keybinds.restart:
        event.preventDefault();
        restart();
        break;

      case 'ArrowUp':
        audo.volume = audo.volume + 0.05;
        event.preventDefault();
        break;

      case 'ArrowDown':
        audo.volume = audo.volume - 0.05;
        event.preventDefault();
        break;

      case 'Escape':
        toggle();
        break;

      case settings.keybinds.track1:
        if (!playing2) return;
        event.preventDefault();
        pressRow({ row: 1 });
        break;

      case settings.keybinds.track2:
        if (!playing2) return;
        event.preventDefault();
        pressRow({ row: 2 });
        break;

      case settings.keybinds.track3:
        if (!playing2) return;
        event.preventDefault();
        pressRow({ row: 3 });
        break;

      case settings.keybinds.track4:
        if (!playing2) return;
        event.preventDefault();
        pressRow({ row: 4 });
        break;
    }
  });

  useEventListener('keyup', (event: any) => {
    if (!audo || !mapData || !key[event.key]) return;
    setKey((key: { [x: string]: boolean; }) => {
      key[event.key] = false;
      return key;
    });
    switch (event.key) {
      case settings.keybinds.track1:
        if (!playing2) return;
        event.preventDefault();
        pressUpRow({ row: 1 });
        break;

      case settings.keybinds.track2:
        if (!playing2) return;
        event.preventDefault();
        pressUpRow({ row: 2 });
        break;

      case settings.keybinds.track3:
        if (!playing2) return;
        event.preventDefault();
        pressUpRow({ row: 3 });
        break;

      case settings.keybinds.track4:
        if (!playing2) return;
        event.preventDefault();
        pressUpRow({ row: 4 });
        break;
    }
  });

  useEffect(() => {
    setPlaying(caller.playing);
  }, [caller.playing]);

  function NoteRenderSinge({
    note,
    index,
    position,
    row,
  }: {
    note: Schemas.Note;
    index: number;
    position: number;
    row: 1 | 2 | 3 | 4;
  }) {
    //const [notePos, setNotePos] = useState(note.privateData.pos || 0) // create a state for the note position (used to update the note position if not used it does not update...)
    const notePosRef = useRef(null);
    const noteColorRef = useRef(null);

    const getColor = (missed: boolean) => {
      note = mapData.notes[index];
      if (note.hit) return new Color(settings.visuals.notes.colors[`track${row}`].hit);
      if (note.missed || missed) return new Color(settings.visuals.notes.colors[`track${row}`].miss);
      return new Color(settings.visuals.notes.colors[`track${row}`].base);
    };

    useFrame(() => {
      const bpm = timeConvert({ time: audo.currentTime, bpm: mapInfo.beatsPerMinute });
      let missed = false;
      if (!note.missed && !note.hit && (bpm - rowTime({ time: note.time, bpm: mapInfo.beatsPerMinute })) >= 7) {
        note.missed = true;
        missed = true;
      }
      notePosRef.current.position.z = bpm - rowTime({ time: note.time, bpm: mapInfo.beatsPerMinute });
      noteColorRef.current.color = getColor(missed);
      //setNotePos(speed * bpm - note.time * speed)
      note.privateData.pos = bpm - rowTime({ time: note.time, bpm: mapInfo.beatsPerMinute });
      mapData.notes.splice(index, 1, { ...note });
    }, 2);



    return (
      <>
        <group ref={notePosRef} position={[position, 1.5, 0]}>
          <mesh position={[0, 0, 0]}>
            <sphereBufferGeometry args={[1.5, 32, 32]} />
            <meshBasicMaterial attach="material" ref={noteColorRef} color={settings.visuals.notes.colors[`track${row}`].base} />
          </mesh>
        </group>
      </>
    );
  }

  function NoteLongPress({ note, index, position, row }) {
    //const [notePos, setNotePos] = useState(note.privateData.pos || 0) // create a state for the note position (used to update the note position if not used it does not update...)
    const notePosRef = useRef(null);
    const noteColorRefStart = useRef(null);
    const noteColorRefEnd = useRef(null);
    const noteColorRef = useRef(null);

    const getColor = (farted: string, missed: boolean) => {
      note = mapData.notes[index];
      switch (farted) {
        case 'start':
          if (note.privateData.startHit)
            return new Color(settings.visuals.notes.colors[`track${row}`].hit);
          if (note.missed || missed)
            return new Color(settings.visuals.notes.colors[`track${row}`].miss);
          return new Color(settings.visuals.notes.colors[`track${row}`].base);

        case 'end':
          if (note.privateData.endHit && note.privateData.startHit)
            return new Color(settings.visuals.notes.colors[`track${row}`].hit);
          if (note.missed || missed)
            return new Color(settings.visuals.notes.colors[`track${row}`].miss);
          return new Color(settings.visuals.notes.colors[`track${row}`].base);

        case 'mid':
          if (note.privateData.endHit)
            return new Color(settings.visuals.notes.colors[`track${row}`].hit);
          if (note.missed || missed)
            return new Color(settings.visuals.notes.colors[`track${row}`].miss);
          return new Color(settings.visuals.notes.colors[`track${row}`].base);
      }
    };

    useFrame(() => {
      const bpm = timeConvert({ time: audo.currentTime, bpm: mapInfo.beatsPerMinute });
      let missed = false;
      if (!note.missed && !note.hit && bpm - rowTime({ time: note['time-end'], bpm: mapInfo.beatsPerMinute }) >= 7) {
        note.missed = true; missed = true;
      }
      if (!note.privateData.startHit && bpm - rowTime({ time: note.time, bpm: mapInfo.beatsPerMinute }) >= 7) {
        note.missed = true; missed = true;
      }
      notePosRef.current.position.z = bpm - rowTime({ time: note.time, bpm: mapInfo.beatsPerMinute });
      noteColorRefStart.current.color = getColor('start', missed);
      noteColorRefEnd.current.color = getColor('end', missed);
      noteColorRef.current.color = getColor('mid', missed);

      note.privateData.pos = bpm - rowTime({ time: note.time, bpm: mapInfo.beatsPerMinute });
      mapData.notes.splice(index, 1, { ...note });
    }, 2);

    return (
      <group ref={notePosRef} position={[position, 1.5, 0]}>
        <mesh position={[0, 0, 0]}>
          <sphereBufferGeometry args={[1.5, 32, 32]} />
          <meshBasicMaterial attach="material" ref={noteColorRefStart} />
        </mesh>

        <mesh
          rotation={[Math.PI / 2, 0, 0]}
          position={[0, 0.25, rowTime({ time: (note.time - note['time-end']), bpm: mapInfo.beatsPerMinute }) / 2]}
        >
          <cylinderBufferGeometry
            args={[0.5, 0.5, rowTime({ time: (note.time - note['time-end']), bpm: mapInfo.beatsPerMinute }), 100]}
          />
          <meshStandardMaterial ref={noteColorRef} />
        </mesh>

        <mesh position={[0, 0, rowTime({ time: (note.time - note['time-end']), bpm: mapInfo.beatsPerMinute })]}>
          <sphereBufferGeometry args={[1.5, 32, 32]} />
          <meshBasicMaterial attach="material" ref={noteColorRefEnd} />
        </mesh>
      </group>
    );
  }

  // Note loading methodology
  const [shouldShake, setShouldShake] = useState(false); // Camera shake on miss (not good)
  function NoteDoDa({
    row,
    position,
  }: {
    row: 1 | 2 | 3 | 4;
    position: number;
  }) {
    const copyProps = useMemo(() => { return { row, position }; }, [row, position]);
    const returnArray: JSX.Element[] = []; // create an empty array to store the notes

    mapData?.notes?.forEach((note: Schemas.Note, index: number) => {
      if (note.hit || note.missed) return <></>;
      if (!note.privateData)
        note.privateData = {
          startHit: false,
          endHit: false,
          pos: 0,
          hitMessage: '',
          hitTime: 0,
        };
      if (note.row === row) {
        if (note.type === 0) {
          returnArray.push(
            <NoteRenderSinge
              note={note}
              index={index}
              position={copyProps.position}
              row={copyProps.row}
            />,
          );
        } else if (note.type === 2) {
          returnArray.push(
            <NoteLongPress
              note={note}
              index={index}
              position={copyProps.position}
              row={copyProps.row}
            />,
          );
        }
      }
    });

    //console.log(returnArray.length === 0);
    return returnArray as any[any];
  }

  const NoteDoDaMemo = useMemo(() => NoteDoDa, [mapInfo?._id]);

  function ThreeSecondStart({
    start,
    setStart,
    audo,
    settings,
    newError,
  }: {
    start: boolean;
    setStart: (start: boolean) => void;
    audo: HTMLAudioElement | undefined;
    settings: Settings;
    newError: (err: string) => void;
  }) {
    let Ostart = false;
    const [text, setText] = useState<string>('');
    const [play, setPlay] = useState(false);

    useEffect(() => {
      if (!audo) return;
      console.log('start', start, Ostart);
      Ostart = start;
      if (!start) return setText('');

      setTimeout(() => {
        setText('');
        setPlay(true);
      }, 3000);
      setTimeout(() => setText('1'), 2000);
      setTimeout(() => setText('2'), 1000);
      setText('3');
    }, [start]);

    useEffect(() => {
      if (!audo) return;
      if (start && play) {
        audo.play();
        setPlay(false);
        setStart(false);
      }
    }, [play, start]);

    return (
      <>
        <Text
          position={[0, 10, 10]}
          fontSize={settings.visuals.text.size / 2}
          color={settings.visuals.text.color}
        >
          {start ? text : ''}
        </Text>
      </>
    );
  }

  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [attemptlogin, setAttemptlogin] = useState(false);
  const [loggedin, setLoggedIn] = useState(false);

  useEffect(() => {
    if (
      window.sessionStorage.getItem('token') &&
      window.sessionStorage.getItem('token')
    )
      setLoggedIn(true);

    new Promise(async (resolve, reject) => {
      if (attemptlogin) {
        const data = await axios.post(
          'https://api.wayofthat.com/api/v1/login/check',
          {
            username,
            password,
          },
          { validateStatus: () => true },
        );
        if (data.status === 200) {
          console.log(data.data);
          window.sessionStorage.setItem('token', data.data.token);
          window.sessionStorage.setItem('id', data.data.id);
          setLoggedIn(true);
          resolve(data.data.token);
        } else {
          setError({ errors: [...error.errors, data.data] });
          setAttemptlogin(false); // reset the attempt login, display wrong password/username
          resolve(false);
        }
      }
    });
  }, [attemptlogin]);

  useEffect(() => {
    if (
      !loggedin ||
      !window.sessionStorage.getItem('token') ||
      !window.sessionStorage.getItem('id')
    )
      return;
    new Promise(async (resolve, reject) => {
      const data = await axios.get(
        `https://api.wayofthat.com/api/v1/user/${window.sessionStorage.getItem(
          'id',
        )}`,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.getItem('token')}`,
          },
          validateStatus: () => true,
        },
      );

      if (data.status === 200) {
        // Set settings if the user has pre exsisting settings.
        setSettings(data.data);
        resolve(data.data);
      } else if (data.status === 404) {
        // Generate a new settings for the user since they dont have one
        const id = window.sessionStorage.getItem('id');
        if (!id || id === '' || !window.sessionStorage.getItem('token')) return;
        defaultSettings._id = id;

        const newSettings = await axios.post(
          `https://api.wayofthat.com/api/v1/user`,
          defaultSettings,
          {
            headers: {
              Authorization: `Bearer ${window.sessionStorage.getItem('token')}`,
            },
            validateStatus: () => true,
          },
        );

        if (newSettings.status === 200) {
          setSettings(newSettings.data);
          resolve(newSettings.data);
        } else {
          console.log(newSettings.status, 'seccond error'); // An error in setting new settings, display to user
          resolve(false);
        }
      } else {
        setLoggedIn(false); // An error in getting the settings, display to user
        resolve(false);
      }
    });
  }, [loggedin]);

  const [loginmenu, setLoginmenu] = useState(false);

  const [signUpInfo, setSignUpInfo] = useState({
    username: '',
    password: '',
    email: '',
    confirmPassword: '',
  });
  const [signUp, setSignUp] = useState(false);

  const trySignUp = async () => {
    if (
      signUpInfo.username === '' ||
      signUpInfo.password === '' ||
      signUpInfo.email === '' ||
      signUpInfo.confirmPassword === ''
    ) {
      setError({ errors: [...error.errors, 'Please fill out all fields.'] });
      return;
    }

    if (signUpInfo.password !== signUpInfo.confirmPassword) {
      setError({ errors: [...error.errors, 'Passwords do not match.'] });
      return;
    }

    const data = await axios.post(
      'https://api.wayofthat.com/api/v1/login/register',
      signUpInfo,
      {
        validateStatus: () => true,
      },
    );

    if (data.status === 200) {
      window.sessionStorage.setItem('token', data.data.token);
      window.sessionStorage.setItem('id', data.data.id);
      setLoggedIn(true);
    } else {
      setError({ errors: [...error.errors, data.data] });
    }
  };

  return (
    <div className="w-full h-screen absolute overflow-hidden">
      <div className="absolute w-full h-screen -z-10">
        <React.Suspense fallback={true}>
          <Canvas
            style={{ backgroundColor: '#010101' }}
            camera={{
              fov: settings.camera.fov,
              position: [0, 10, 30],
              zoom: 1,
            }}
          >
            <Camera2 playing={playing2} settings={settings.camera} />
            <fog color="#010101" attach="fog" near={200} far={250} />
            <ThreeSecondStart
              audo={audo}
              settings={settings}
              newError={newError}
              start={startCountDown}
              setStart={setStartCountDown}
            />
            <ambientLight />

            {caller.mapID && <LoadTapNote TapControles={setTapNote} />}
            {caller.mapID && (
              <Refsaredumb mapID={caller.mapID} controles={audioControles} />
            )}

            <Text
              color={settings.visuals.text.color}
              font={settings.visuals.text.font}
              fontSize={settings.visuals.text.size}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'center'}
              position={[0, 20, -30]}
            >
              {mapInfo && mapInfo.title}
            </Text>

            <Bloom>

              <Text position={[30, 0, -40]}>
                <GetLastHit settings={settings} />
              </Text>

              <ambientLight />

              <mesh
                position={[0, -10, 0]}
                rotation={[-Math.PI / 2, 0, Math.PI / 2]}
              >
                <planeGeometry args={[1000, 1000]} />
                <MeshReflectorMaterial
                  resolution={1024}
                  mirror={0.25}
                  mixBlur={10}
                  mixStrength={5}
                  blur={[0, 0]}
                  minDepthThreshold={0}
                  maxDepthThreshold={3}
                  depthScale={1}
                  depthToBlurRatioBias={0.2}
                  distortion={0}
                  color={"#010101"}
                  metalness={0.5}
                  roughness={2}
                />
              </mesh>

              <group>
                <NoteDoDaMemo row={4} position={6} />
                <mesh
                  rotation={[Math.PI / 2, 0, 0]}
                  position={[6, 0, 0]}
                  castShadow={true}
                  frustumCulled={false}
                >
                  <cylinderBufferGeometry args={[1, 1, 10000, 100]} />
                  <meshStandardMaterial
                    color={settings.visuals.tracks.colors.track4}
                    roughness={2}
                  />
                </mesh>
              </group>

              <group>
                <NoteDoDaMemo row={3} position={2} />
                <mesh rotation={[Math.PI / 2, 0, 0]} position={[2, 0, 0]}>
                  <cylinderBufferGeometry args={[1, 1, 10000, 100]} />
                  <meshStandardMaterial
                    color={settings.visuals.tracks.colors.track3}
                    roughness={2}
                  />
                </mesh>
              </group>

              <group>
                <NoteDoDaMemo row={2} position={-2} />
                <mesh rotation={[Math.PI / 2, 0, 0]} position={[-2, 0, 0]}>
                  <cylinderBufferGeometry args={[1, 1, 10000, 100]} />
                  <meshStandardMaterial
                    color={settings.visuals.tracks.colors.track2}
                    roughness={2}
                  />
                </mesh>
              </group>

              <group>
                <NoteDoDaMemo row={1} position={-6} />
                <mesh rotation={[Math.PI / 2, 0, 0]} position={[-6, 0, 0]}>
                  <cylinderBufferGeometry args={[1, 1, 10000, 100]} />
                  <meshStandardMaterial
                    color={settings.visuals.tracks.colors.track1}
                    roughness={2}
                  />
                </mesh>
              </group>

              <mesh position={[0, 0, 0]}>
                <boxGeometry attach="geometry" args={[15, 2.1, 3]} />
                <meshStandardMaterial attach="material" color={'#303030'} />
              </mesh>
            </Bloom>
          </Canvas>
        </React.Suspense>
      </div>
      {ended && mapInfo && mapData && (
        <>
          <div className="absolute w-full h-full flex flex-col items-center justify-center pointer-events-none">
            <div className="text-white min-h-[11rem] w-96 bg-base-dark/90 border-2 border-accent-1 rounded-md">
              <div className="flex flex-col items-center space-y-4 my-4 pointer-events-auto">
                <span className="text-center">
                  <p className="text-xl">Match Summary</p>
                  <p className="text-lg">{`${mapInfo.title}`}</p>
                  <p className="text-sm">{`${mapInfo.author}`}</p>
                </span>

                <div className="border border-accent-1 w-full" />

                <span className="space-y-2">
                  <div className="flex flex-col flex-wrap items-stretch">
                    <p className="text-center">{`Hit: ${mapData.notes.filter((n) => n.hit).length
                      }  Missed: ${mapData.notes.length -
                      mapData.notes.filter((n) => n.hit).length
                      }`}</p>
                    <div className="border border-white" />
                    <p className="text-center">Total: {mapData.notes.length}</p>
                  </div>

                  <div className="flex flex-row flex-wrap space-x-2">
                    <p className="text-sm">{`Accuracy: ${getAccuracy(
                      mapData,
                    )}%`}</p>
                    <p className="text-sm">{`Score: ${getScore(mapData)}`}</p>
                  </div>
                </span>

                <div className="border border-accent-1 w-full" />
                <span>
                  <motion.button
                    onClick={() => {
                      setEnded(false);
                      caller.setMapID(undefined);
                    }}
                    className="bg-accent-1 p-1 px-2 mx-2 rounded-md hover:shadow-lg"
                    whileHover={{ scale: 1.05 }}
                    animate={{ scale: 1 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ ease: 'easeInOut' }}
                  >
                    New Song
                  </motion.button>

                  <motion.button
                    onClick={restart}
                    className="border-2 border-accent-1 p-1 px-2 mx-2 rounded-md hover:shadow-lg"
                    whileHover={{ scale: 1.05 }}
                    animate={{ scale: 1 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ ease: 'easeInOut' }}
                  >
                    Restart
                  </motion.button>
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {!playing2 && mapInfo && <>
        {!loggedin && (
          <motion.button
            animate={{ x: 0 }}
            initial={{ x: 200 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
            whileHover={{ scale: 1.1 }}
            className="w-20 p-2 text-center text-white hover:text-base-dark hover:shadow-lg shadow-accent-1 delay-200 absolute top-2 right-2 bg-accent-1 ease-in-out duration-300 hover:scale-105 hover:translate-y-1 rounded-full"
            onClick={() => setLoginmenu(!loginmenu)}
          >
            Login
          </motion.button>
        )}
        {loginmenu && !loggedin && (
          <div className="absolute z-20 flex flex-row items-center justify-center w-screen h-screen pointer-events-none">
            <motion.div
              animate={{ scale: 1 }}
              initial={{ scale: 0 }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
            >
              <div className="bg-base-dark/70 border-2 border-accent-1 w-96 rounded-lg p-4 text-white pointer-events-auto">
                {!signUp ? (
                  <>
                    <div className="flex flex-row items-center justify-center ">
                      <div className="flex flex-col items-center p-2">
                        <p>Username</p>
                        <input
                          id="diasdasdf"
                          type="text"
                          placeholder={username}
                          className="bg-accent-1 text-center w-36 rounded-md focus:outline-none focus:ring-0 pl-1"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col items-center p-2">
                        <p>Password</p>
                        <input
                          id="dif"
                          type="password"
                          placeholder={password}
                          className="bg-accent-1 text-center w-36 rounded-md focus:outline-none focus:ring-0 pl-1"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <button
                        className="border-accent-1 border-2 rounded-md w-20"
                        onClick={() => {
                          if (username && password)
                            return setAttemptlogin(true);
                        }}
                      >
                        Login
                      </button>
                      <button
                        className="bg-accent-1 rounded-md w-20 ml-2"
                        onClick={() => setSignUp(true)}
                      >
                        SignUp
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="grid grid-cols-4 gap-4 grid-flow-row justify-items-center">
                    <div className="col-span-2 justify-self-center">
                      <p className="text-center">Username</p>
                      <input
                        id="adsasdasdadsdas"
                        type="text"
                        placeholder={signUpInfo.username}
                        className="bg-accent-1 text-center w-full rounded-md focus:outline-none focus:ring-0 pl-1"
                        onChange={(e) => {
                          setSignUpInfo({
                            ...signUpInfo,
                            username: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-2 justify-self-center">
                      <p className="text-center">Email</p>
                      <input
                        id="adsadsasdadsadsdas"
                        type="email"
                        placeholder={signUpInfo.email}
                        className="bg-accent-1 text-center w-full rounded-md focus:outline-none focus:ring-0 pl-1"
                        onChange={(e) =>
                          setSignUpInfo({
                            ...signUpInfo,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-span-2 justify-self-center">
                      <p className="text-center">Password</p>
                      <input
                        id="dasdasdadasdasdasdif"
                        type="password"
                        placeholder={signUpInfo.password}
                        className="bg-accent-1 text-center w-full rounded-md focus:outline-none focus:ring-0 pl-1"
                        onChange={(e) =>
                          setSignUpInfo({
                            ...signUpInfo,
                            password: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-span-2 justify-self-center">
                      <p className="text-center">Confirm Password</p>
                      <input
                        id="difasdsadsadsad"
                        type="password"
                        placeholder={signUpInfo.confirmPassword}
                        className="bg-accent-1 text-center w-full rounded-md focus:outline-none focus:ring-0 pl-1"
                        onChange={(e) =>
                          setSignUpInfo({
                            ...signUpInfo,
                            confirmPassword: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-span-4">
                      <button
                        className="border-2 border-accent-1 px-2 rounded-full"
                        onClick={() => {
                          setSignUp(false);
                        }}
                      >
                        Login
                      </button>
                      <button
                        className=" bg-accent-1 px-2 ml-2 rounded-full"
                        onClick={() => {
                          trySignUp();
                        }}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          </div>
        )}

        <div>
          <SettingsComponent
            audio={audo}
            mapID={caller.mapID}
            setMapID={caller.setMapID}
            settings={settings}
            setSettings={setSettings}
            mapInfo={mapInfo}
            newError={newError}
          />
        </div>
      </>}

      {<DisplayError error={error} setError={setError} />}
    </div>
  );
}

export default Stage;