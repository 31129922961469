import { useRef, useEffect } from "react";
import { Settings } from "../../../schema/types";

export const playSound = (
  tapNote: HTMLAudioElement | undefined,
  settings: Settings,
) => {
  if (!tapNote) return
  tapNote.volume = settings.audio.sfx
  const playPromise = tapNote.play()

  if (playPromise !== undefined) {
    playPromise
      .then(function () {
        // Automatic playback started!
      })
      .catch(function (error) {
        console.log(error)
        // Automatic playback failed.
        // Show a UI element to let the user manually start playback.
      })
  }
}

export const LoadTapNote = ({ TapControles }: any): JSX.Element | null => {
  const tapNote = useRef<HTMLAudioElement>(new Audio('./sounds/tap.mp3'))

  useEffect(() => {
    TapControles(tapNote.current)
  }, [tapNote])

  return null
}

export default playSound