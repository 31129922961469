import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

function Settings({ error, setError }: { error: { errors: string[]; }, setError: any; }): any {
  useEffect(() => {
    console.log(error);
  }, [error]);

  function remover() {
    setTimeout(() => { error.errors.shift(); }, 5000);
  }

  function handleChange() {
    let errorComponent: JSX.Element[] = [];
    error.errors.forEach((err, index) => {
      errorComponent.push(
        <motion.div
          className={`absolute z-[100] right-4 p-2 border-2 rounded-full border-red-600 text-white animate-pulse`}
          style={{
            bottom: `${index * 50 + 16}px`,
          }}
          animate={{
            x: [200, 0, 0, 0, 500],
            scale: [0, 1, 1.1, 1, 1.1, 1]
          }}

          transition={{ duration: 5 }}
        >
          <p>{err}</p>
        </motion.div>
      );
      remover();
    });
    return errorComponent;
  }
  return (
    <>
      {handleChange()}
    </>
  );
}

export default Settings;