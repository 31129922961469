import { useEffect, useState, useRef, useMemo } from "react";

const PlayAudio = (url: string): [boolean, () => void, [number, (vol: number) => void, boolean, () => void, number, HTMLAudioElement]] => {
    const audio = useRef(useMemo(() => new Audio(url), [url]));
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(false);
    const intervalRef = useRef<any>();
    const [curtentTime, setCurrentTime] = useState(0);

    const toggle = () => setPlaying(!playing);
    const [volume, setVolume] = useState<number>(.5);

    const setVol = (vol: number) => { audio.current.volume = vol; setVolume(vol); }

    const muteToggle = () => setMuted(!muted);
    const changeVol = (vol: number) => { setVol(vol); }

    useEffect(() => { playing ? audio.current.play() : audio.current.pause() }, [playing, audio] );
    useEffect(() => { audio.current.muted = muted }, [muted, audio] );
    useEffect(() => {
        audio.current.addEventListener('ended', () => setPlaying(false));

        // Decrease this time and get more lag but better play shit
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                setCurrentTime(audio.current.currentTime);
            }, 10);
        }
        return () => {
            clearInterval(intervalRef.current);
            audio.current.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audio]);

    return [playing, toggle, [volume, changeVol, muted,  muteToggle, curtentTime, audio.current]];
};

export default PlayAudio;